import I18N from 'assets/i18n/brandlift-project';

import {SurveyPrice, Wallet, PaymentStatus, AudienceRequest} from 'client/common/types';
import {AudienceTargeting} from 'client/common/utils/audience';
import {formatPriceValue} from 'client/common/utils/format';
import {checkPayed} from 'client/common/utils/payment';

import {SurveySettingsMode} from 'client/common/components/survey-settings/types';

const i18n = I18N.common.surveySettings;

export const getIsFree = (price: SurveyPrice) => {
    return price.value_with_vat === 0 || price.value_without_vat === 0;
};

export const canPayByWallet = (price: SurveyPrice, wallet: Wallet) => {
    if (wallet.balance_with_vat && price.value_with_vat) {
        return wallet.balance_with_vat >= price.value_with_vat;
    }

    if (wallet.balance_without_vat && price.value_without_vat) {
        return wallet.balance_without_vat >= price.value_without_vat;
    }

    return false;
};

const DEFAULT_QUICKSURVEY_SALE_MULTIPLIER = 2;

export function getSalePriceValue({
    priceValue,
    mode,
    request
}: {
    priceValue: number;
    mode: SurveySettingsMode;
    request: AudienceRequest;
}): number | undefined {
    if (mode === 'simple' && AudienceTargeting.isDefaultAudience(request)) {
        return priceValue * DEFAULT_QUICKSURVEY_SALE_MULTIPLIER;
    }
}

export function getSurveyPriceLabels(price: SurveyPrice) {
    const isFree = getIsFree(price);
    const {value_with_vat: priceWithVat, value_without_vat: priceWithoutVat, currency} = price;

    const priceLabel = isFree
        ? i18n.free
        : formatPriceValue({value: priceWithoutVat || priceWithVat, currency});
    const isVatHintVisible = !isFree && Boolean(priceWithoutVat && priceWithVat);
    const vatHint = isVatHintVisible
        ? `${formatPriceValue({value: priceWithVat, currency})} ${i18n.withVAT}`
        : undefined;

    return {priceLabel, vatHint, isFree};
}

export function getWalletBalanceLabels(wallet: Wallet) {
    const {
        currency,
        balance_with_vat: balanceWithVat,
        balance_without_vat: balanceWithoutVat
    } = wallet;

    const balanceLabel = formatPriceValue({
        value: balanceWithoutVat || balanceWithVat,
        currency: currency || 'RUB'
    });
    const isVatHintVisible = Boolean(balanceWithoutVat && balanceWithVat);
    const vatHint = isVatHintVisible
        ? formatPriceValue({
              value: balanceWithVat,
              currency: currency || 'RUB'
          })
        : undefined;

    return {balanceLabel, vatHint};
}

export function getNeedToPay(price: SurveyPrice, paymentStatus: PaymentStatus) {
    const isFree = getIsFree(price);
    const isPayed = checkPayed(paymentStatus);

    return !isFree && !isPayed;
}

export function getIsWalletAvailable(
    price: SurveyPrice,
    paymentStatus: PaymentStatus,
    wallet: Wallet
) {
    const needToPay = getNeedToPay(price, paymentStatus);

    return needToPay && canPayByWallet(price, wallet);
}
