import React, {Fragment, useRef, useState} from 'react';
import b_ from 'b_';

import {useIsMobile} from 'client/common/hooks/device';
import useWindowSize from 'react-use/lib/useWindowSize';
import {Button, Tooltip, TooltipStateful} from 'client/common/components/lego';
import {openLink} from 'client/common/utils/window';
import {tld} from 'client/common/utils/tld';
import {Lang} from 'client/common/types';
import {useUser} from 'client/common/contexts/user';

import ru from './ru.png';
import en from './en.png';
// import tr from './tr.png';
import globus from './globus.svg';
import arrow from './arrow.svg';
import check from './check.svg';

import './lang-switcher.css';

const b = b_.with('lang-switcher');

interface LangSwitcherProps {
    lang: Lang;
    className?: string;
    size?: 's' | 'm';
    withLabel?: boolean;
    updated?: boolean;
    onLangSelect?: () => void;
}

const SWITCHABLE_LANGS = ['ru', 'en'] as const;
const LANG_OPTIONS: Record<Lang, {label: string; img: string; intLabel: string}> = {
    ru: {
        // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
        label: 'Русский',
        img: ru,
        intLabel: 'Ru'
    },
    en: {
        label: 'English',
        img: en,
        intLabel: 'En'
    } /* ,
    tr: {
        label: 'Türkçe',
        img: tr
    } */
};

// Формируем retpath без параметра lang, который перебивает язык, выбранный пользователем
function getRetpath() {
    const url = new URL(location.pathname, location.origin);

    url.searchParams.delete('lang');

    return url.href;
}

function LangSelect({
    lang,
    secretkey,
    updated,
    onLangSelect
}: LangSwitcherProps & {secretkey: string}) {
    const isMobile = useIsMobile();

    const switchLang = (selectedLang: Lang) => {
        if (lang === selectedLang || !secretkey) {
            return;
        }

        const retpath = encodeURIComponent(getRetpath());

        openLink(
            `https://yandex.${tld}/portal/set/lang/?intl=${selectedLang}&sk=${secretkey}&retpath=${retpath}`
        );
    };

    return (
        <ul className={b('select')}>
            {SWITCHABLE_LANGS.map(currentLang => {
                const url = new URL(window.location.href);

                url.searchParams.set('lang', currentLang);

                return (
                    <li
                        className={b('item', {updated, selected: lang === currentLang})}
                        key={currentLang}
                        onClick={() => {
                            switchLang(currentLang);
                            onLangSelect?.();
                        }}
                    >
                        {updated && !isMobile && (
                            <div className={b('check-wrapper')}>
                                {lang === currentLang && <img src={check} alt='check' />}
                            </div>
                        )}
                        <a className={b('lang')} href={url.href} onClick={e => e.preventDefault()}>
                            <>
                                {!updated && (
                                    <img
                                        className={b('flag')}
                                        src={LANG_OPTIONS[currentLang].img}
                                    />
                                )}
                                <span className={b('flag-label')}>
                                    {updated
                                        ? LANG_OPTIONS[currentLang].intLabel
                                        : LANG_OPTIONS[currentLang].label}
                                </span>
                            </>
                        </a>
                    </li>
                );
            })}
        </ul>
    );
}

export default function LangSwitcher(props: LangSwitcherProps) {
    const {lang, className = '', size = 's', withLabel = false, updated = false} = props;
    const user = useUser();
    const {width} = useWindowSize();

    const btnRef = useRef<HTMLButtonElement>(null);

    const [showTooltip, setShowTooltip] = useState(false);

    if (updated) {
        return (
            <>
                {width < 1100 ? (
                    <div className={`${b('new-tooltip-horizontal')} ${className}`}>
                        <LangSelect
                            lang={lang}
                            secretkey={user?.secretkey ?? ''}
                            updated
                            onLangSelect={() => setShowTooltip(false)}
                        />
                    </div>
                ) : (
                    <>
                        <button
                            ref={btnRef}
                            className={`${b('new-tooltip-btn', {open: showTooltip})} ${className}`}
                            onClick={() => setShowTooltip(true)}
                        >
                            <img className={b('flag')} src={globus} />
                            <span className={b('flag-label')}>{LANG_OPTIONS[lang].intLabel}</span>
                            <img src={arrow} alt='arrow' className={b('arrow-down')} />
                        </button>
                        <Tooltip
                            visible={showTooltip}
                            onClose={() => setShowTooltip(false)}
                            anchor={btnRef}
                            className={b('new-tooltip')}
                        >
                            <LangSelect
                                lang={lang}
                                secretkey={user?.secretkey ?? ''}
                                updated
                                onLangSelect={() => setShowTooltip(false)}
                            />
                        </Tooltip>
                    </>
                )}
            </>
        );
    }

    return (
        <>
            <TooltipStateful
                content={<LangSelect lang={lang} secretkey={user?.secretkey ?? ''} />}
                className={b('tooltip')}
                direction={['bottom-end', 'bottom-start']}
                mainOffset={8}
                size='s'
                trigger='click'
                view='default'
                zIndex={1001}
                keepMounted={false}
            >
                <Button className={`${b({size})} ${className}`} size={size}>
                    {withLabel ? (
                        <>
                            <img className={b('flag')} src={LANG_OPTIONS[lang].img} />
                            <span className={b('flag-label')}>{LANG_OPTIONS[lang].label}</span>
                        </>
                    ) : (
                        <img className={b('flag')} src={LANG_OPTIONS[lang].img} />
                    )}
                </Button>
            </TooltipStateful>
        </>
    );
}
