import React from 'react';
import b_ from 'b_';
import Select, {components} from 'react-select';
import I18N from 'assets/i18n/brandlift-project';

import {useCurrentOrg, useOrgsList, useChooseUserOrg} from 'client/common/hooks/orgs';
import {Button} from 'client/common/components/lego';

import './org-selector.css';

const i18n = I18N.common.orgSelector;
const b = b_.with('org-selector');

const OWN_SURVEYS_ID = undefined;

const Input = (props: React.ComponentProps<typeof components.Input>) => {
    return <components.Input {...props} autoComplete={props.autoComplete} />;
};

const CustomOption = (props: React.ComponentProps<typeof components.Option>) => {
    const chooseUserOrg = useChooseUserOrg();

    if (!('value' in props)) {
        return null;
    }

    const {value, isFocused, isSelected, label} = props;

    return (
        <div
            className={b('access-option', {
                selected: isSelected,
                focused: isFocused
            })}
        >
            <h4 className={b('access-orgname')} title={label}>
                {label}
            </h4>
            <div className={b('access-btns')}>
                <Button
                    className={b('access-switch')}
                    view='dl-default'
                    size='s'
                    disabled={isSelected}
                    pin={value ? 'round-clear' : undefined}
                    onClick={async () => {
                        await chooseUserOrg(value as number);
                    }}
                >
                    {i18n.switchBtn}
                </Button>
                {value && (
                    <Button
                        pin='brick-round'
                        className={b('access-btn')}
                        view='dl-default'
                        size='s'
                        type='link'
                        url={`/org/access?org=${value}`}
                    >
                        {i18n.accessBtn}
                    </Button>
                )}
            </div>
        </div>
    );
};

const NoOptionsMessage = (props: any) => {
    return (
        <components.NoOptionsMessage {...props}>
            <span>{i18n.noOptionsMessage}</span>
        </components.NoOptionsMessage>
    );
};

export function OrgSelector() {
    const {isSuccess, data} = useOrgsList();

    const options: {
        value: number | undefined;
        label: string;
    }[] = (data?.orgs ?? []).map(org => ({value: org.id, label: org.name}));

    const orgOptions = options.concat({value: OWN_SURVEYS_ID, label: i18n.ownSurveys}) ?? [];

    const orgId = useCurrentOrg();
    const currentOrgOption = orgOptions.find(option => option.value === (orgId ?? OWN_SURVEYS_ID));

    return isSuccess && orgOptions.length > 1 ? (
        <>
            <Select
                // onChange={e => handleOrgChange(e!.value)}
                options={orgOptions}
                value={currentOrgOption}
                className={b()}
                size='m'
                view='default'
                components={{Input, Option: CustomOption, NoOptionsMessage}}
                option
                // отключаем автокомплит хрома/ябро
                autoComplete='one-time-code'
                styles={{
                    menu: base => ({
                        ...base,
                        width: '300px',
                        borderRadius: '12px',
                        zIndex: 10,
                        overflow: 'hidden'
                    }),
                    menuList: base => ({...base, paddingTop: 0, paddingBottom: 0}),
                    option: base => ({...base, paddingRight: '36px'}),
                    control: base => ({...base, borderRadius: '12px'}),
                    valueContainer: base => ({...base, padding: '2px 12px'})
                }}
            />
        </>
    ) : null;
}
