import React from 'react';

import type {UserStore} from 'client/common/store/reducers/user/types';
import {BundleData} from '../types/bundle';

const UserContext = React.createContext<UserStore | undefined>(undefined);

export function useUser() {
    return React.useContext(UserContext);
}

export const UserProvider = UserContext.Provider;

const ServerDataContext = React.createContext<BundleData['serverData'] | undefined>(undefined);

export function useServerData() {
    return React.useContext(ServerDataContext);
}

export const ServerDataProvider = ServerDataContext.Provider;
