import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './block-close.css';

export interface IconTypeBlockCloseProps {
    type?: 'block-close';
}

export const withTypeBlockClose = withBemMod<IconTypeBlockCloseProps>(cnIcon(), {
    type: 'block-close'
});
