import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './horizontal-menu.css';

export interface IconTypeHorizontalMenuProps {
    type?: 'horizontal-menu';
}

export const withTypeHorizontalMenu = withBemMod<IconTypeHorizontalMenuProps>(cnIcon(), {type: 'horizontal-menu'});
