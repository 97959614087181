import React, {useState} from 'react';
import b_ from 'b_';

import I18N from 'assets/i18n/root';

import './faq.css';

const b = b_.with('faq');

const i18n = I18N.faq;

type List = typeof i18n.list;

type FaqKey = keyof Omit<List, 'order'>;
type Entries = [number, string];

const Faq = ({name, content}: {name: FaqKey; content: Array<Entries>}) => {
    const [opened, setOpened] = useState(false);

    const handleToggle = (e: any) => {
        setOpened(isOpened => !isOpened);

        e.stopPropagation();
    };

    return (
        <article key={name} className={b('item', {active: opened ? 'yes' : 'no'})}>
            <h5 className={b('header')} onClick={handleToggle}>
                <span className={b('header-inner')}>{i18n.list[name].header}</span>
            </h5>
            <div className={b('content', {isOpen: opened})}>
                {content.map((sentence, index) => {
                    return (
                        <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            className={b('paragraph-wrapper')}
                        >
                            {name === 'start' && index !== content.length - 1 && (
                                <div className={b('ordering')}>{index + 1}</div>
                            )}
                            <div
                                className={b('paragraph')}
                                dangerouslySetInnerHTML={{
                                    __html: sentence[1]
                                }}
                            />
                        </div>
                    );
                })}
            </div>
            <button
                onClick={handleToggle}
                title={opened ? i18n.toggleBtn.close : i18n.toggleBtn.open}
                className={b('toggle-btn')}
            >
                <div className={b('circle-plus', {closed: opened ? 'no' : 'yes'})}>
                    <div className={b('circle')}>
                        <div className={b('horizontal')} />
                        <div className={b('vertical')} />
                    </div>
                </div>
            </button>
        </article>
    );
};

const FaqList = () => {
    return (
        <div className={b()}>
            <h4 className={b('title')}>{i18n.title}</h4>
            <div className={b('list')}>
                {i18n.list.order
                    .split(',')
                    .map(str => str.trim() as FaqKey)
                    .map(name => {
                        const content = Object.entries(i18n.list[name].content)
                            .map(entry => [parseInt(entry[0], 10), entry[1]])
                            .sort((x, y) => {
                                if (x[0] === y[0]) {
                                    return 0;
                                }

                                return x[0] < y[0] ? -1 : 1;
                            }) as Entries[];

                        return <Faq key={name} name={name} content={content} />;
                    })}
            </div>
        </div>
    );
};

export default FaqList;
