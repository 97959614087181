import {_doRequest} from '../../base';
import {
    CopyPageItemParams,
    CopyQuestionResponse,
    CreateQuestionParams,
    CreateQuestionResponse,
    RemoveItemParams,
    UpdateQuestionParams
} from '../../types';

export const createQuestion = async (
    params: CreateQuestionParams
): Promise<CreateQuestionResponse> => {
    const {insertAt, pageId, revisionId, slug, ...data} = params;

    const requestParams = {
        method: 'POST',
        // eslint-disable-next-line max-len
        url: `survey/${slug}/revision/${revisionId}/question?group=${pageId}${
            insertAt === undefined ? '' : `&insertAt=${insertAt}`
        }`,
        data
    };

    return _doRequest(requestParams);
};

export const removeQuestion = async ({slug, revisionId, id, force}: RemoveItemParams) => {
    const requestParams = {
        method: 'DELETE',
        url: `survey/${slug}/revision/${revisionId}/question/${id}?force=${force}`
    };

    return _doRequest(requestParams);
};
export const copyQuestion = async (params: CopyPageItemParams): Promise<CopyQuestionResponse> => {
    const {slug, revisionId, id, pageId} = params;

    const requestParams = {
        method: 'POST',
        url: `survey/${slug}/revision/${revisionId}/question/${id}/copy?group=${pageId}`
    };

    return _doRequest(requestParams);
};
export const updateQuestion = async ({slug, revisionId, id, force, data}: UpdateQuestionParams) => {
    const requestParams = {
        method: 'PATCH',
        url: `survey/${slug}/revision/${revisionId}/question/${id}?force=${force}`,
        data
    };

    return _doRequest(requestParams);
};
