import {SurveySlug} from 'client/common/types';
import {_doRequest} from '../base';
import {GetSurveyStatisticsParams, GetSurveyStatisticsResponse} from '../types';

export const getSurveyUplifts = async ({slug}: {slug: string}) => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `report/brandlift/${slug}/uplifts`
    };

    return _doRequest(requestParams);
};

export const getBrandliftFilters = async ({slug}: {slug: string}) => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `report/brandlift/${slug}/filters`
    };

    return _doRequest(requestParams);
};

export const getSurveyStatistics = async ({
    slug,
    filter
}: GetSurveyStatisticsParams): Promise<GetSurveyStatisticsResponse> => {
    const query = filter?.key
        ? `?filters[${encodeURIComponent(filter.key)}]=${encodeURIComponent(filter.value)}`
        : '';

    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `report/brandlift/${slug}/all${query}`
    };

    return _doRequest(requestParams);
};

export const getBrandliftExcelFullDownloadUrl = (slug: SurveySlug) => {
    return `report/brandlift/${slug}/excel/full?timestamp=${Date.now()}`;
};
