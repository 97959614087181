import {useCallback} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';

import {UserProfile} from 'client/common/types';

import {getUserProfile, updateUserProfile} from 'client/common/utils/api-client';

const USER_PROFILE_KEY = 'user-profile';

export function useUserProfile() {
    return useQuery(USER_PROFILE_KEY, async () => {
        const {data} = await getUserProfile();

        return data.profile;
    });
}

export function useUpdateUserProfile() {
    const queryClient = useQueryClient();
    const {mutateAsync} = useMutation(updateUserProfile, {
        onSuccess: async () => {
            await queryClient.refetchQueries(USER_PROFILE_KEY);
        }
    });

    return useCallback(
        async (profile: UserProfile) => {
            return mutateAsync(profile);
        },
        [mutateAsync]
    );
}
