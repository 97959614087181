import React, {ReactNode} from 'react';
import b_ from 'b_';

import {useIsMobile} from 'client/common/hooks/device';

import {RootGoal} from 'client/common/types';

import {useIsAuthorized} from 'client/common/hooks/user';
import {createUrl} from 'client/common/utils/history';
import {LinkPage} from 'client/common/utils/header-link';
import {sendGoal} from 'client/common/utils/metrika';

import I18N from 'assets/i18n/root';

import {TryBtn} from '../buttons';

import './main-slide.css';

const b = b_.with('main-slide');

const i18n = I18N.mainSlide;
const buttonLabels = I18N.buttons;

/* eslint-disable max-statements */
const MainSlide = ({
    children,
    withNotification
}: {
    children: ReactNode;
    withNotification: boolean;
}) => {
    const isMobile = useIsMobile();

    const referenceUrl = document.referrer ? new URL(document.referrer) : null;

    const currentUrl = new URL(window.location.href);

    const isUserLogedIn = useIsAuthorized();

    const isInternalTransition = () => {
        if (referenceUrl) {
            return referenceUrl.origin === currentUrl.origin;
        }

        return false;
    };
    const isUserLoggedAndOnSite = isInternalTransition() && isUserLogedIn;

    let buttonText;
    let buttonUrl;
    let handleStartClick;

    if (isUserLoggedAndOnSite) {
        buttonText = buttonLabels.backToService;
        buttonUrl = createUrl({link: LinkPage.QuicksurveyList});
        handleStartClick = () => sendGoal(RootGoal.ClickLandingBackToSurveys);
    } else {
        buttonText = buttonLabels.try;
        buttonUrl = createUrl({link: LinkPage.Quicksurvey});
        handleStartClick = () => sendGoal(RootGoal.ClickLandingStart);
    }

    return (
        <div className={b('layout')}>
            <div className={b()}>
                <div className={b('header', {notification: withNotification ? 'yes' : 'no'})}>
                    {children}
                </div>
                <div className={b('wrapper')}>
                    <h1 className={b('title')}>{i18n.titleNew}</h1>
                    <TryBtn
                        width={isMobile ? 'max' : 'auto'}
                        buttonText={buttonText}
                        buttonUrl={buttonUrl}
                        handleStartClick={handleStartClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default MainSlide;
