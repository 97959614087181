import {_doRequest} from '../base';
import {
    GetUserFeaturesResponse,
    EditUserFeaturesParams,
    GetUserAvailableCountriesResponse,
    CreateWalletTopUpLinkParams,
    CreateWalletTopUpLinkResponse,
    GetAgencyClientsResponse,
    GetExperimentsResponse,
    GetPayersResponse,
    GetUserProfileResponse,
    GetWalletBalanceResponse,
    SaveUserExperimentsParams,
    SaveUserExperimentsResponse,
    SetUserCurrencyParams,
    SuccessfulResponse,
    UpdateAudienceRequestResponse,
    UpdateUserProfileParams,
    GetUserAvailableCurrenciesResponse
} from '../types';

export const getUserFeatures = async (): Promise<GetUserFeaturesResponse> => {
    const requestParams = {
        method: 'GET',
        url: 'user/features'
    };

    return _doRequest(requestParams);
};

export const editUserFeatures = async ({
    logins,
    features
}: EditUserFeaturesParams): Promise<any> => {
    const query = logins.map(login => `login=${login}`).join('&');
    const data = features.map(id => ({id}));

    const requestParams = {
        method: 'PUT',
        url: `user/features?${query}`,
        data
    };

    return _doRequest(requestParams);
};

export const getUserAvailableCountries = async (): Promise<GetUserAvailableCountriesResponse> => {
    const requestParams = {
        method: 'GET',
        url: 'user/country/available'
    };

    return _doRequest(requestParams);
};

export const getUserAvailableCurrencies = async (): Promise<GetUserAvailableCurrenciesResponse> => {
    const requestParams = {
        method: 'GET',
        url: 'user/currency/available'
    };

    return _doRequest(requestParams);
};

export const getExperiments = async (): Promise<GetExperimentsResponse> => {
    const requestParams = {
        method: 'GET',
        url: 'user/experiment'
    };

    return _doRequest(requestParams);
};

export const saveUserExperiments = async ({
    experiments
}: SaveUserExperimentsParams): Promise<SaveUserExperimentsResponse> => {
    const requestParams = {
        method: 'POST',
        url: 'user/experiment/',
        data: experiments,
        passQuery: false
    };

    return _doRequest(requestParams);
};

export const getPayers = async (): Promise<GetPayersResponse> => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: 'user/payers/'
    };

    return _doRequest(requestParams);
};

export const getAgencyClients = async (): Promise<GetAgencyClientsResponse> => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: 'user/agency-clients'
    };

    return _doRequest(requestParams);
};

export const getUserProfile = async (): Promise<GetUserProfileResponse> => {
    const requestParams = {
        method: 'GET',
        url: 'user/profile'
    };

    return _doRequest(requestParams);
};

export const getWalletBalance = async (): Promise<GetWalletBalanceResponse> => {
    const requestParams = {
        method: 'GET',
        url: 'user/wallet'
    };

    return _doRequest(requestParams);
};

export const updateUserProfile = async (
    profile: UpdateUserProfileParams
): Promise<UpdateAudienceRequestResponse> => {
    const requestParams = {
        method: 'PUT',
        url: 'user/profile',
        data: profile
    };

    return _doRequest(requestParams);
};

export const createWalletTopUpLink = async (
    params: CreateWalletTopUpLinkParams
): Promise<CreateWalletTopUpLinkResponse> => {
    const {amount, payer, retpath} = params;

    const requestParams = {
        method: 'POST',
        url: 'user/wallet',
        data: {amount, payer, retpath}
    };

    return _doRequest(requestParams);
};

export const setUserCurrency = async (
    params: SetUserCurrencyParams
): Promise<SuccessfulResponse> => {
    const {currency, countryId} = params;
    const requestParams = {
        method: 'POST',
        url: 'user/currency',
        data: {currency, countryId}
    };

    return _doRequest(requestParams);
};
