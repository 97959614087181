import React from 'react';
import b_ from 'b_';

import I18N from 'assets/i18n/root';

import './benefits.css';
import OurClients from '../our-clients/our-clients';

const i18n = I18N.benefits;

const b = b_.with('benefits');

function getBenefitByIdx(idx: keyof typeof i18n.list) {
    return i18n.list[idx];
}

function Advantages() {
    return (
        <div className={b()}>
            <div className={b('audience')}>
                <div className={b('audience-wrapper')}>
                    <div className={b('audience-tile-image')} />
                    <div>
                        <h3 className={b('primary-title')}>{getBenefitByIdx('0').title}</h3>
                        <p className={b('description')}>{getBenefitByIdx('0').desc}</p>
                    </div>
                </div>
            </div>
            <div className={b('targets')}>
                <div className={b('targets-wrapper')}>
                    <div className={b('targets-tile-image')} />
                    <div className={b('targets-texts')}>
                        <h3 className={b('secondary-title')}>{getBenefitByIdx('1').title}</h3>
                        <p className={b('description')}>{getBenefitByIdx('1').desc}</p>
                    </div>
                </div>
            </div>
            <div className={b('diversity')}>
                <div className={b('tile-wrapper')}>
                    <div className={b('top-tile')}>
                        <h3 className={b('tertiary-title')}>{getBenefitByIdx('2').title}</h3>
                        <div className={b('tile-icon', {diversity: true})} />
                    </div>
                    <p className={b('description')}>{getBenefitByIdx('2').desc}</p>
                </div>
            </div>
            <div className={b('flexibility')}>
                <div className={b('tile-wrapper')}>
                    <div className={b('top-tile')}>
                        <h3 className={b('tertiary-title')}> {getBenefitByIdx('3').title}</h3>
                        <div className={b('tile-icon', {flexibility: true})} />
                    </div>
                    <p className={b('description')}>{getBenefitByIdx('3').desc}</p>
                </div>
            </div>
        </div>
    );
}

export default function Benefits() {
    return (
        <div className={b('layout')}>
            <div className={b('wrapper')}>
                <Advantages />
                <OurClients />
            </div>
        </div>
    );
}
