import {SurveyPreset} from 'common/survey';
import {SurveyState} from 'client/common/store/reducers/survey/types';
import {PaymentStatus, SurveyAudience, SurveyPrice, VideoService} from 'client/common/types';
import {Metric} from 'client/common/types/brandlift/types';

import {
    ChoiceQuestion,
    DateQuestion,
    EmotionQuestion,
    GeoQuestion,
    NumberQuestion,
    Question,
    RatingQuestion,
    Revision,
    ScaleQuestion,
    UXQuestion
} from './revision';
import {UserAvatar} from './user';

export type SurveySlug = string;

export enum SurveyLang {
    Ru = 'ru',
    En = 'en',
    Az = 'az',
    Am = 'am',
    Ar = 'ar',
    Hy = 'hy',
    Be = 'be',
    Ka = 'ka',
    He = 'he',
    Id = 'id',
    Es = 'es',
    It = 'it',
    Kk = 'kk',
    Ky = 'ky',
    De = 'de',
    No = 'no',
    Pl = 'pl',
    Pt = 'pt-br',
    Ro = 'ro',
    Sr = 'sr',
    Tg = 'tg',
    Tr = 'tr',
    Uz = 'uz',
    Ur = 'ur',
    Fi = 'fi',
    Fr = 'fr',
    Hi = 'hi'
}

export enum SurveyTextDirection {
    LTR = 'ltr',
    RTL = 'rtl'
}

export enum SurveyScope {
    Yandex = 'yandex',
    YandexNet = 'yandexNet',
    YandexTeam = 'yandexTeam',
    External = 'external'
}

export interface ModerationVerdict {
    state?: 'REJECTED' | 'PASSED' | 'RESTRICTED';
    info?: {
        reasons?: number[];
    };
}

export type NextBtnBehaviourType = 'visibleAndEnabled' | 'disabledUntilValid' | 'hiddenUntilValid';

export type CharLimitKey = 'longText' | 'shortText' | 'answerWithText';

export type CharLimits = Partial<Record<CharLimitKey, number>> & {
    disableOverflow?: boolean;
};

export interface SurveySettings {
    dir?: SurveyTextDirection;
    lang: SurveyLang;
    metrika: string[];
    metrikaTitle: string;
    is_public?: boolean;
    redirects: {
        default: string;
        isDisabled?: boolean;
    };
    respReuse: boolean;
    scope: SurveyScope[];
    supportDarkMode?: boolean;
    nextBtnBehaviour?: NextBtnBehaviourType;
    charLimits?: CharLimits;
    squeeze?: {
        enabled: boolean;
        path?: string;
    };
    determinedRotation?: {
        choice?: boolean;
        sidebyside?: boolean;
    };
}

export interface MetrikaCounter {
    id: number;
    name: string;
}

export interface SurveyResponseRevision extends Pick<Revision, 'id' | 'name' | 'state'> {
    createdAt: string;
    updatedAt: string;
}

export interface BrandliftContext {
    brand: string;
    brands: string[];
    brandType?: string;
    industry: string;
    adMessage?: string | string[];
    adMessages?: string[];
    customMetrics?: Metric[];
    metricLabels?: {[metrik in Metric]: string};
    metricOptions?: {[metrik in Metric]: string[]};
    isCustomFormulations?: boolean;
    defaultMetricLabels?: {[metrik in Metric]: string};
}

export interface BrandliftBox {
    name: 'detachedBrandLift';
    context: BrandliftContext;
    // TODO legacy, убрать когда не останется таких брендлифтов
    customMetrics?: Metric;
}

export interface VideoBoxContext {
    email?: string;
}

export interface VideoBoxBox {
    name: 'videoBox' | 'videoBoxKZ';
    context: VideoBoxContext;
}

export interface Segment {
    lookalikeValue: 1 | 2 | 3 | 4 | 5;
    // Это позиция вопроса, а не его ID
    questionIndex: number;
    // Это позиция ответа, а не его ID
    questionAnswer: number;
    login?: string;
}

export interface CreateSegmentContext {
    email?: string;
    segment: Segment;
}

export interface CreateSegmentBox {
    name: 'createSegment';
    context: CreateSegmentContext;
}

export interface Creative {
    id: string;
    title: string;
    service: VideoService | 'resource';
    duration: number;
    previewUrl?: string;
    extraParams?: string;
}

export interface CreativeCustomQuestion extends Pick<ChoiceQuestion, 'multipleChoice'> {
    label: string;
    options: string[];
}

export interface CreativeContext {
    brand?: string;
    industry?: number;
    creative?: Creative;
    customQuestions?: {
        enabled?: boolean;
        questions: CreativeCustomQuestion[];
    };
    optionalQuestions?: {
        heroes?: boolean;
        story?: boolean;
    };
    options?: {
        about?: string[];
        image?: string[];
    };
}

export interface BordomatikContext {
    brand?: string;
    industry?: number;
    creative?: Creative;
    castingRole?: string;
    customQuestions?: {
        enabled?: boolean;
        questions: CreativeCustomQuestion[];
    };
    optionalQuestions?: {
        packshots?: boolean;
        slogans?: boolean;
        casting?: boolean;
    };
    options?: {
        about?: string[];
        image?: string[];
        brand?: string[];
        packshots?: string[];
        slogans?: string[];
        casting?: string[];
    };
}

export interface CreativeBox {
    name: 'creative';
    context: CreativeContext;
}
export interface BordomatikBox {
    name: 'bordomatik';
    context: BordomatikContext;
}
export function isCreativeBox(box: Box): box is CreativeBox {
    return box.name === 'creative';
}
export function isBordomatikBox(box: Box): box is BordomatikBox {
    return box.name === 'bordomatik';
}

export type UXCustomQuestion =
    | ChoiceQuestion
    | ScaleQuestion
    | EmotionQuestion
    | RatingQuestion
    | GeoQuestion
    | NumberQuestion
    | DateQuestion;

export interface UXQuestionConfig extends Pick<UXQuestion, 'areas' | 'image' | 'label'> {
    questions: Record<Question['id'], UXCustomQuestion>;
    nextBtnLabel?: string;
}

export interface UXCustomQuestions {
    enabled: boolean;
    nextBtnLabel?: string;
    questions: Record<Question['id'], UXCustomQuestion>;
}

export interface UXContext {
    email?: string;
    task: string;
    questions: UXQuestionConfig[];
    customQuestions?: UXCustomQuestions;
}

export interface UXBox {
    name: 'ux';
    context: UXContext;
}

export interface SerpSurveyBox {
    name: 'serpSurvey';
    context: SerpSurveyContext;
}

export interface SerpSurveyContext {
    test?: string;
}

export interface FeedbackContext {
    type: string;
}

export interface FeedbackBox {
    name: 'feedback';
    context: FeedbackContext;
}

export type Box =
    | SerpSurveyBox
    | BrandliftBox
    | VideoBoxBox
    | CreateSegmentBox
    | CreativeBox
    | BordomatikBox
    | UXBox
    | FeedbackBox;

export type BoxContext = Box['context'];
export type BoxType = Box['name'];
export type SurveyType = BoxType | 'PRO';

export type VHVideoState =
    | 'banned'
    | 'moderator_banned'
    | 'error'
    | 'new'
    | 'transcoding'
    | 'moderating'
    | 'ready';

export interface Resource {
    id: string;
    type: 'vh_video';
    resourceData: {
        videoMetaId: string;
        shortId?: string;
        state: VHVideoState;
    };
}

export interface VideoMetaFlags {
    withAds: boolean;
    access: 'private' | 'public';
}

export interface RutubeVideoMeta {
    id: string;
    title: string;
    thumbnail_url: string;
    duration: number;
    flags: VideoMetaFlags;
}

export interface TemplateWelcome {
    title: string;
    text: string;
}

export interface Survey {
    box?: Box;
    clientId: string | null;
    isDemo: boolean;
    isTemporary: boolean;
    name: string;
    purpose?: {
        selected: SurveyPreset;
    };
    revisions: SurveyResponseRevision[];
    settings: SurveySettings;
    slug: SurveySlug;
    state: SurveyState;
    templateWelcome?: TemplateWelcome;
    updatedAt: string;
    createdAt: string;
    accountId?: number;
}

export interface SurveyListItem {
    box?: Survey['box'];
    slug: Survey['slug'];
    name: Survey['name'];
    state: Survey['state'];
    createdAt: string;
    updatedAt: Survey['updatedAt'];
    audience: SurveyAudience;
    clientId: Survey['clientId'];
    clientName?: string;
    cost: SurveyPrice;
    surveyType: SurveyType;
    surveyScope: SurveyScope;
    isDemo?: boolean;
    resources?: Resource[];
    paymentStatus: PaymentStatus;
    moderationVerdict?: ModerationVerdict['state'];
}

export interface SurveyList {
    paginationKey: number;
    surveys: SurveyListItem[];
}

export interface AccessListUser {
    avatar?: UserAvatar;
    displayName?: string;
    email?: string;
    login: string;
}

export interface AccessList {
    users: AccessListUser[];
}

export interface CopySurveyOptions {
    access?: boolean;
    audience?: boolean;
    clientId?: string;
    metrika?: boolean;
    redirects?: boolean;
    orgId?: number;
}

export enum ConstructorTab {
    Questions,
    Settings
}
