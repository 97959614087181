import {Domain} from 'common/domain';

import {GeoCountry, GeoValue} from 'client/common/types';

function getTld(): Domain {
    const tld = window.location.origin.split('.').pop();

    if (tld === 'tr') {
        return Domain.ComTr;
    }

    if (!Object.values(Domain).includes(tld as Domain)) {
        return Domain.Ru;
    }

    return (tld as Domain) || Domain.Ru;
}

export const tld = getTld();

export const getGeoIdFromDomain = (domain: Domain): GeoCountry => {
    return {
        [Domain.Ru]: GeoValue.Russia,
        [Domain.By]: GeoValue.Belarus,
        [Domain.Kz]: GeoValue.Kazakhstan,
        [Domain.Ua]: GeoValue.Ukraine,
        [Domain.Com]: GeoValue.Russia,
        [Domain.ComTr]: GeoValue.Turkey,
        [Domain.Uz]: GeoValue.Uzbekistan
    }[domain] as GeoCountry;
};
