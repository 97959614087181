import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './block-open.css';

export interface IconTypeBlockOpenProps {
    type?: 'block-open';
}

export const withTypeBlockOpen = withBemMod<IconTypeBlockOpenProps>(cnIcon(), {type: 'block-open'});
