import React, {Fragment, useRef} from 'react';
import {Helmet} from 'react-helmet';
import {Route, Switch} from 'react-router-dom';
import b_ from 'b_';
import {MetricaExperimentsProvider} from 'yandex-metrica-ab-react';
import I18N from 'assets/i18n/root';

import cfg from 'client/common/utils/client-config';
import {useRumCounter} from 'client/common/utils/rum';
import {LinkPage} from 'client/common/utils/header-link';
import {Lang} from 'client/common/types';
import {ErrorBoundary} from 'client/common/components/error';

import {useHaveSurveys} from 'client/common/hooks/user';
import {useScrollPageParamSending} from 'client/common/hooks/metrika';
import {useUser} from 'client/common/contexts/user';

import {Header, headerMeaningfulSelector} from './components/header';
import FeatureList from './components/feature-list';
import Benefits from './components/benefits';
import Reviews from './components/reviews';
import SuccessStories from './components/success-stories';
import Faq from './components/faq';
import {PageFooter} from './components/page-footer';
import MainSlide from './components/main-slide';
import FormWidget from './components/form-widget';

import CrowdPage from './crowd';

import './root.css';
import FeedbackForm from './components/feedback-form';

const i18n = I18N.page;
const b = b_.with('root');

const LandingPage = ({lang, haveSurveys = false}: {lang: Lang; haveSurveys?: boolean}) => {
    const refs = {
        features: useRef<HTMLDivElement>(null),
        benefits: useRef<HTMLDivElement>(null),
        reviews: useRef<HTMLDivElement>(null),
        stories: useRef<HTMLDivElement>(null),
        feedback: useRef<HTMLDivElement>(null),
        faq: useRef<HTMLDivElement>(null)
    };

    const user = useUser();

    useScrollPageParamSending(Object.values(refs), 500);

    return (
        <MetricaExperimentsProvider
            clientId={`metrika.${cfg.metrika.counters?.surveys}`}
            clientFeatures={{lang, hasSurveys: String(haveSurveys)}}
        >
            <MainSlide withNotification={false}>
                <Header lang={lang} />
            </MainSlide>

            <div ref={refs.benefits} id='benefits'>
                <Benefits />
            </div>

            <div ref={refs.features} id='features'>
                <FeatureList />
            </div>
            <div ref={refs.reviews} id='reviews'>
                <Reviews />
            </div>
            <div ref={refs.stories} id='stories'>
                <SuccessStories />
            </div>
            <div ref={refs.faq} id='faq'>
                <Faq />
            </div>
            <div ref={refs.feedback} id='feedback'>
                <FeedbackForm lang={user?.lang || 'ru'} />
            </div>
            <div className={b('page-footer')}>
                <PageFooter />
            </div>
            <div>
                <FormWidget />
            </div>
        </MetricaExperimentsProvider>
    );
};

export default function Root() {
    const user = useUser();
    const haveSurveys = useHaveSurveys();

    useRumCounter({
        page: 'quicksurvey.root',
        meaningfulPaintSelector: headerMeaningfulSelector,
        testBuckets: user?.testBuckets ?? []
    });

    return (
        <Fragment>
            <Helmet>
                <title>{i18n.title}</title>
            </Helmet>
            <div className={b()}>
                <ErrorBoundary position='center'>
                    <Switch>
                        <Route
                            exact
                            path={['/', LinkPage.Landing]}
                            component={() => (
                                <LandingPage lang={user?.lang ?? 'ru'} haveSurveys={haveSurveys} />
                            )}
                        />
                        <Route
                            exact
                            path='/landing/crowd'
                            component={() => <CrowdPage user={user!} />}
                        />
                    </Switch>
                </ErrorBoundary>
            </div>
        </Fragment>
    );
}
