import React, {useEffect, useState} from 'react';

import {Swiper, SwiperSlide, SwiperClass} from 'swiper/react';

import 'swiper/css';
import './swiper.css';

import b_ from 'b_';

const b = b_.with('draggable-swiper');

interface SwiperProps {
    children?: React.ReactNode;
    spaceBetweenSlides?: number;
    pagination?: boolean;
    slidesPerView?: 'auto' | number;
    controller?: React.MutableRefObject<SwiperClass | undefined>;
}

export default function DraggableSwiper({
    children,
    spaceBetweenSlides = 16,
    pagination = false,
    slidesPerView = 'auto',
    controller
}: SwiperProps) {
    const [swiper, setSwiper] = useState<SwiperClass>();
    const [currentIdx, setCurrentIdx] = useState(0);

    useEffect(() => {
        if (controller) {
            controller.current = swiper;
        }
    }, [swiper, controller]);

    const moveToSlide = (idx: number) => {
        if (!swiper) return;

        swiper.slideTo(idx);
    };

    return (
        <div className={b('swiper-wrapper')}>
            <Swiper
                spaceBetween={spaceBetweenSlides}
                slidesPerView={slidesPerView}
                className={b('swiper')}
                onActiveIndexChange={(swp: SwiperClass) => setCurrentIdx(swp.realIndex)}
                onSwiper={(swiperInstance: SwiperClass) => setSwiper(swiperInstance)}
            >
                {children &&
                    React.Children.map(children, child => (
                        <SwiperSlide>{React.cloneElement(child as React.ReactElement)}</SwiperSlide>
                    ))}
            </Swiper>

            {pagination && (
                <div className={b('pagination')}>
                    {(children as Array<React.ReactChild>).map((_, idx) => (
                        <div
                            className={b('point', {active: currentIdx === idx})}
                            onClick={() => moveToSlide(idx)}
                            // eslint-disable-next-line react/no-array-index-key
                            key={idx}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}
