import {useMemo} from 'react';

import {useUser} from 'client/common/contexts/user';

export function useIsAuthorized(): boolean {
    const user = useUser();

    return Boolean(user?.info?.uid);
}

export function useIsSuperuser(): boolean {
    const user = useUser();

    return user?.info?.isSuperuser ?? false;
}

export function useIsSuperreader(): boolean {
    const user = useUser();

    return user?.info?.isSuperreader ?? false;
}

export function useIsSuper(): boolean {
    const isSuperuser = useIsSuperuser();
    const isSuperreader = useIsSuperreader();

    return isSuperuser || isSuperreader;
}

export function useIsCreativeAssessor(): boolean {
    const user = useUser();

    return user?.info?.isCreativeAssessor ?? false;
}

export function useIsSupport(): boolean {
    const user = useUser();

    return user?.info?.isSupport ?? false;
}

export function useIsYandexPublisher(): boolean {
    const user = useUser();

    return user?.info?.isYandexPublisher ?? false;
}

export function useIsDeveloper(): boolean {
    const user = useUser();

    return user?.info?.isDeveloper ?? false;
}

export function useIsLauncherRole() {
    const isSuperuser = useIsSuperuser();
    const isCreativeAssessor = useIsCreativeAssessor();

    return isSuperuser || isCreativeAssessor;
}

export interface UserRoles {
    isAuthorized: boolean;
    isSuperuser: boolean;
    isSuperreader: boolean;
    isSuper: boolean;
    isCreativeAssessor: boolean;
    isSupport: boolean;
    isYandexPublisher: boolean;
    isLauncherRole: boolean;
    isDeveloper: boolean;
}

export function UserRoles(params?: Partial<UserRoles>): UserRoles {
    const defaultValues: UserRoles = {
        isAuthorized: false,
        isCreativeAssessor: false,
        isDeveloper: false,
        isLauncherRole: false,
        isSuper: false,
        isSuperreader: false,
        isSuperuser: false,
        isSupport: false,
        isYandexPublisher: false
    };

    return Object.assign(defaultValues, params);
}

export function useUserRoles(): UserRoles {
    const isAuthorized = useIsAuthorized();
    const isCreativeAssessor = useIsCreativeAssessor();
    const isLauncherRole = useIsLauncherRole();
    const isSuper = useIsSuper();
    const isDeveloper = useIsDeveloper();
    const isSuperreader = useIsSuperreader();
    const isSuperuser = useIsSuperuser();
    const isSupport = useIsSupport();
    const isYandexPublisher = useIsYandexPublisher();

    return useMemo(() => {
        return {
            isAuthorized,
            isCreativeAssessor,
            isLauncherRole,
            isSuper,
            isSuperreader,
            isSuperuser,
            isSupport,
            isYandexPublisher,
            isDeveloper
        };
    }, [
        isAuthorized,
        isCreativeAssessor,
        isLauncherRole,
        isSuper,
        isSuperreader,
        isSuperuser,
        isSupport,
        isYandexPublisher,
        isDeveloper
    ]);
}

export function useUserPermissions() {
    const user = useUser();

    return user?.info?.permissions ?? {};
}
