export function isWithinDates({from = new Date(), to = new Date()}) {
    const now = new Date();

    return now >= from && now < to;
}

export function parseISOString(data: string) {
    const date = new Date(data);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return {
        day,
        month,
        year
    };
}

const isValidHours = (hours: number) => hours >= 0 && hours <= 23;
const isValidMinutes = (minutes: number) => minutes >= 0 && minutes <= 59;
const isValidSeconds = (seconds: number) => seconds >= 0 && seconds <= 59;

export const validateTime = (time: string) => {
    const [hoursRaw, minutesRaw, secondsRaw] = time.split(':');
    const hours = parseInt(hoursRaw, 10);
    const minutes = parseInt(minutesRaw, 10);
    const seconds = parseInt(secondsRaw, 10);

	if (Number.isNaN(hours) || Number.isNaN(minutes) || Number.isNaN(seconds))
		return false;

    return isValidHours(hours) && isValidMinutes(minutes) && isValidSeconds(seconds);
};

const isValidYear = (year: string) => year.length === 4;
const isValidMonth = (month: number) => month >= 1 && month <= 12;
const isValidDay = (day: number) => day >= 1 && day <= 31;

export const validateDate = (date: string) => {
    const [yearRaw, monthRaw, dayRaw] = date.split('-');
    const year = parseInt(yearRaw, 10);
    const month = parseInt(monthRaw, 10);
    const day = parseInt(dayRaw, 10);

    if (Number.isNaN(month) || Number.isNaN(day) || Number.isNaN(year))
        return false;

    return isValidYear(yearRaw) && isValidMonth(month) && isValidDay(day);
};

export const getTzOffset = (timeZone = 'UTC', date = new Date()) => {
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));

    const diff = tzDate.getTime() - utcDate.getTime();

    return diff;
};

export const formatTzOffset = (offset: number) => {
    const sign = Math.sign(offset);
    const dt = new Date();

    dt.setTime(Math.abs(offset));
    const h = String(dt.getUTCHours()).padStart(2, '0');
    const m = String(dt.getUTCMinutes()).padStart(2, '0');

    return `${sign >= 0 ? '+' : '-'}${h}:${m}`;
};

export const makeDateTime = (date: string, time: string, tzOffset: number) => {
    const utcDate = new Date(`${date}T${time}Z`);

    utcDate.setTime(utcDate.getTime() - tzOffset);

    return utcDate.getTime();
};
