import {useCallback} from 'react';
import {UseQueryResult, useMutation, useQuery} from 'react-query';

import {UserFeatures} from 'client/common/types';

import {getUserFeatures, editUserFeatures} from 'client/common/utils/api-client';

const USER_FEATURES_KEY = 'user-features';

export function useUserFeatures(): UseQueryResult<UserFeatures> {
    return useQuery(USER_FEATURES_KEY, async () => {
        const {data} = await getUserFeatures();

        return data;
    });
}

export function useEditUserFeatures() {
    const {mutateAsync} = useMutation(editUserFeatures);

    return useCallback(
        async ({logins, features}: {logins: any; features: any[]}) => {
            return mutateAsync({logins, features});
        },
        [mutateAsync]
    );
}
