import React from 'react';
import b_ from 'b_';

import ArrowRight from './arrow-right.svg';
import ArrowLeft from './arrow-left.svg';

import './arrows-panel.css';

const b = b_.with('arrows-panel');

interface Props {
    onLeftArrowClick: () => void;
    onRightArrowClick: () => void;
    className?: string;
}

const ArrowsPanel = ({onLeftArrowClick, onRightArrowClick, className = ''}: Props) => {
    return (
        <div className={`${b()} ${className}`}>
            <button onClick={onLeftArrowClick}>
                <img src={ArrowLeft} alt='arrow left' />
            </button>
            <button onClick={onRightArrowClick}>
                <img src={ArrowRight} alt='arrow right' />
            </button>
        </div>
    );
};

export default ArrowsPanel;
