import React, {useRef, useState} from 'react';
import b_ from 'b_';

import {RootGoal, SurveysParam} from 'client/common/types';

import {sendGoal, sendParams} from 'client/common/utils/metrika';
import {useIsMobileOrTablet} from 'client/common/hooks/device';
import {Split} from 'client/common/types';

import {BaseButton} from '../buttons';
import DraggableSwiper from '../swiper';
import ArrowsPanel from '../arrows-panel';

import ProSurvey from './pro.jpeg';
import UxSurvey from './ux.jpeg';
import CreativeSurvey from './creative.jpeg';
import CrowdSurvey from './crowd.jpeg';
import SimpleSurvey from './simple.jpeg';
import Varioqub from './varioqub.jpeg';

import I18N from 'assets/i18n/root';

import './feature-list.css';

type Features = typeof i18n.features;
type FeatureWithLink = keyof Pick<
    Features,
    | 'create_own'
    | 'creatives'
    | 'ux'
    | 'brand_awareness'
    | 'customer_awareness'
    | 'brand_concept'
    | 'crowd'
>;

const b = b_.with('feature-list');

const i18n = I18N.featureList;
const tryButtonLabel = I18N.buttons.try;

const surveysToDisplay = i18n.features.order.split(',') as Split<typeof i18n.features.order, ','>;

const imageMaps: Record<(typeof surveysToDisplay)[number], string> = {
    create_own: ProSurvey,
    ux: UxSurvey,
    creatives: CreativeSurvey,
    crowd: CrowdSurvey,
    simple: SimpleSurvey,
    varioqub: Varioqub
};

const scrollStep = 250;

export function FeatureListDesktop({
    handleFeatureLinkClick
}: {
    handleFeatureLinkClick: (currentSurvey: (typeof surveysToDisplay)[number]) => void;
}) {
    const [selectedIdx, setSelectedIdx] = useState(0);

    const container = useRef<HTMLDivElement>(null);

    const currentSurvey = surveysToDisplay[selectedIdx];

    const {label, descr: description} = i18n.features[currentSurvey];

    const handleClick = (idx: number) => {
        setSelectedIdx(idx);
    };

    const handleRightClick = () => {
        if (container && container.current) {
            container.current.scrollBy({left: scrollStep, behavior: 'smooth'});
        }
    };

    const handleLeftClick = () => {
        if (container && container.current) {
            container.current.scrollBy({left: -scrollStep, behavior: 'smooth'});
        }
    };

    return (
        <>
            <div className={b('main-panel')}>
                <div className={b('tabs-wrapper')}>
                    <div className={b('tabs')} ref={container}>
                        {surveysToDisplay.map((survey, idx) => {
                            return (
                                <span
                                    key={survey}
                                    className={b('tab', {
                                        active: selectedIdx === idx ? 'yes' : 'no',
                                        isVarioqub: survey === 'varioqub'
                                    })}
                                    onClick={() => handleClick(idx)}
                                >
                                    {i18n.features[survey].label}
                                </span>
                            );
                        })}
                    </div>
                    <ArrowsPanel
                        onLeftArrowClick={handleLeftClick}
                        onRightArrowClick={handleRightClick}
                        className={b('arrows')}
                    />
                </div>

                <div className={b('panel')}>
                    <div className={b('panel-left')}>
                        <div className={b('panel-top')}>
                            <h3 className={b('panel-title')}>{label}</h3>
                            <p className={b('panel-desc')}>{description}</p>
                        </div>
                        <div className={b('panel-bottom')}>
                            <BaseButton
                                onClick={() => handleFeatureLinkClick(currentSurvey)}
                                url={i18n.featureLinks[currentSurvey as FeatureWithLink].link}
                            >
                                {tryButtonLabel}
                            </BaseButton>
                        </div>
                    </div>
                    <div
                        className={b('panel-right', {alignLeft: currentSurvey === 'varioqub'})}
                        style={{backgroundImage: `url(${imageMaps[currentSurvey]})`}}
                    />
                </div>
            </div>
        </>
    );
}

export default function FeatureList() {
    const isMobileOrTablet = useIsMobileOrTablet();

    const handleFeatureLinkClick = (currentSurvey: (typeof surveysToDisplay)[number]) => {
        sendParams([SurveysParam.SelectedCategory, currentSurvey]);
        sendGoal(RootGoal.ClickFeatureLink);
    };

    return (
        <div className={b()}>
            <div className={b('wrapper')}>
                <div className={b('header-wrapper')}>
                    <h2 className={b('header')}>{i18n.title}</h2>
                    <span className={b('subheader')}>{i18n.subtitle}</span>
                </div>
                {isMobileOrTablet ? (
                    <DraggableSwiper spaceBetweenSlides={10} pagination slidesPerView={1}>
                        {surveysToDisplay.map(type => (
                            <Slide
                                label={i18n.features[type].label}
                                descr={i18n.features[type].descr}
                                handleFeatureLinkClick={handleFeatureLinkClick}
                                surveyType={type}
                                key={type}
                                bordered
                            />
                        ))}
                    </DraggableSwiper>
                ) : (
                    <FeatureListDesktop handleFeatureLinkClick={handleFeatureLinkClick} />
                )}
            </div>
        </div>
    );
}

interface Props {
    label: string;
    descr: string;
    surveyType: (typeof surveysToDisplay)[number];
    handleFeatureLinkClick: (currentSurvey: (typeof surveysToDisplay)[number]) => void;
    bordered?: boolean;
}

function Slide({label, descr, surveyType, handleFeatureLinkClick, bordered}: Props) {
    return (
        <div className={b('slide', {bordered})}>
            <div
                className={b('slide-top', {alignLeft: surveyType === 'varioqub'})}
                style={{backgroundImage: `url(${imageMaps[surveyType]})`}}
            />
            <div className={b('slide-bottom')}>
                <div className={b('upper-section')}>
                    <h3 className={b('slide-title')}>{label}</h3>
                    <p className={b('slide-desc')}>{descr}</p>
                </div>
                <div className={b('lower-section')}>
                    <BaseButton
                        width='max'
                        onClick={() => handleFeatureLinkClick(surveyType)}
                        url={i18n.featureLinks[surveyType as FeatureWithLink].link}
                    >
                        {tryButtonLabel}
                    </BaseButton>
                </div>
            </div>
        </div>
    );
}
