import React from 'react';
import I18N from 'assets/i18n/root';

import {createUrl} from 'client/common/utils/history';
import {sendGoal} from 'client/common/utils/metrika';
import {LinkPage} from 'client/common/utils/header-link';
import {RootGoal} from 'client/common/types';

import {BaseButton} from './base-button';

const i18n = I18N.buttons;

interface TryBtnProps {
    width?: 'max' | 'auto';
    buttonText?: string;
    buttonUrl?: string;
    handleStartClick?: () => void;
}

export const TryBtn = (props: TryBtnProps) => {
    const {width = 'auto', buttonText, buttonUrl, handleStartClick} = props;

    const handleClick = () => {
        sendGoal(RootGoal.ClickLandingTry);
    };

    return (
        <BaseButton
            onClick={handleStartClick ? handleStartClick : handleClick}
            url={buttonUrl ? buttonUrl : createUrl({link: LinkPage.Quicksurvey})}
            width={width}
        >
            {buttonText ? buttonText : i18n.try}
        </BaseButton>
    );
};
