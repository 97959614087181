import {useCallback} from 'react';
import {UseQueryResult, useMutation, useQuery} from 'react-query';

import type {Currency} from 'common/currency';
import type {GeoCountry} from 'client/common/types';

import {setUserCurrency, getUserAvailableCurrencies} from 'client/common/utils/api-client';
import {useUser} from 'client/common/contexts/user';

const USER_AVAILABLE_CURRENCIES = 'user-available-currencies';

export function useUserCurrency(): Currency | undefined {
    const user = useUser();

    return user?.info?.settings.currency;
}

export function useIsAvailableUserCurrency(): boolean {
    const userCurrency = useUserCurrency();
    const availableCurrencies = useUserAvailableCurrencies().data ?? [];

    return userCurrency ? availableCurrencies.includes(userCurrency) : false;
}

export function useSetUserCurrency() {
    const {mutateAsync} = useMutation(setUserCurrency);

    return useCallback(
        async ({currency, countryId}: {currency: Currency; countryId: GeoCountry}) => {
            return mutateAsync({currency, countryId});
        },
        [mutateAsync]
    );
}

export function useUserAvailableCurrencies(): UseQueryResult<Currency[]> {
    return useQuery(USER_AVAILABLE_CURRENCIES, async () => {
        const {data} = await getUserAvailableCurrencies();

        return data?.currencies;
    });
}
