import React from 'react';

import {useIsMobileOrTablet} from 'client/common/hooks/device';

import {Story} from './success-stories';

import './success-stories.css';

import b_ from 'b_';

const b = b_.with('success-stories');

export function Slide({item, header, quotation}: {item: Story; header: string; quotation: string}) {
    const isMobileOrTablet = useIsMobileOrTablet();

    return (
        <div className={b('accordion-body-header')}>
            <div className={b('accordion-body-top')}>
                <div className={b('accordion-body-title')}>
                    <div
                        style={{backgroundImage: `url(${item.person.img})`}}
                        className={b('accordion-body-card-img-mobile')}
                    />
                    {header}
                </div>
                <div className={b('accordion-body-icons')}>
                    <span>
                        <div className={b('accordion-body-increase')}>
                            <img
                                className={b('accordion-body-icon')}
                                src={item.icon1.icon}
                                alt=''
                            />
                            {item.icon1.increase}
                        </div>
                        <div className={b('accordion-body-inc-text')}>{item.icon1.text}</div>
                    </span>
                    <span>
                        <div className={b('accordion-body-increase')}>
                            <img
                                className={b('accordion-body-icon')}
                                src={item.icon2.icon}
                                alt=''
                            />
                            {item.icon2.increase}
                        </div>
                        <div className={b('accordion-body-inc-text')}>{item.icon2.text}</div>
                    </span>
                </div>
                <hr className={b('accordion-body-hr')} />
            </div>
            <div className={b('accordion-body-bottom')}>
                <div
                    style={{backgroundImage: `url(${item.person.img})`}}
                    className={b('accordion-body-card-img')}
                />
                <div
                    className={b('accordion-body-card-text')}
                    style={{background: isMobileOrTablet ? item.bgColorDark : 'none'}}
                >
                    <div className={b('accordion-body-card-quotation')}>{quotation}</div>
                    <div className={b('accordion-body-card-details')}>
                        <div className={b('accordion-body-card-name')}>{item.person.name}</div>
                        <div>{item.person.position}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
