import {_doRequest} from 'client/common/utils/api-client/base';
import {ErrorResponse, SuccessfulResponse} from 'client/common/utils/api-client/types';

export interface OrgUser {
    userid: number;
    lang: string;
    email: string;
    login: string;
    displayName: string;
    avatar: {
        default: string;
        empty: boolean;
    };
}

interface OrgUsersResponse {
    users: OrgUser[];
}

export const getOrgUsers = async (
    orgId: number
): Promise<SuccessfulResponse<OrgUsersResponse> | ErrorResponse> => {
    const requestParams = {
        method: 'GET',
        prefix: '/api/v1',
        url: `/orgs/${orgId}/users`,
        passQuery: false
    } as const;

    return _doRequest(requestParams);
};

export const addOrgUser = async ({
    orgId,
    login
}: {
    orgId: number;
    login: string;
}): Promise<SuccessfulResponse<OrgUsersResponse>> => {
    const requestParams = {
        method: 'PUT',
        prefix: '/api/v1',
        url: `/orgs/${orgId}/users`,
        passQuery: false,
        data: {login}
    } as const;

    return _doRequest(requestParams);
};

export const removeOrgUser = async ({
    orgId,
    login
}: {
    orgId: number;
    login: string;
}): Promise<SuccessfulResponse<OrgUsersResponse>> => {
    const requestParams = {
        method: 'DELETE',
        prefix: '/api/v1',
        url: `/orgs/${orgId}/users`,
        passQuery: false,
        data: {login}
    } as const;

    return _doRequest(requestParams);
};
