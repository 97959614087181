import React, {useState} from 'react';
import b_ from 'b_';

import I18N from 'assets/i18n/root';

import {useIsMobileOrTablet} from 'client/common/hooks/device';
import {Split} from 'client/common/types';

import {Slide} from './slide';

import PerekrestokImg from './perekrestok.jpeg';
import AvonImg from './avon.jpeg';
import EldoradoImg from './eldorado.jpeg';

import Arrow from './arrow.svg';
import Eye from './eye.svg';
import Heart from './heart.svg';
import People from './people.svg';
import Plus from './plus.svg';

import './success-stories.css';

import DraggableSwiper from '../swiper/swiper';

const b = b_.with('success-stories');

const i18n = I18N.stories;

const sideLabelWidth = 96;

const order = i18n.order.split(',') as Split<typeof i18n.order, ','>;

const specificsMap = {
    avon: {img: AvonImg, bg: '#4697FB', contrastBg: '#3789EE'},
    eldorado: {img: EldoradoImg, bg: '#47BDFF', contrastBg: '#1BA4F0'},
    perekrestok: {img: PerekrestokImg, bg: '#202842', contrastBg: '#323555'}
};

const stories = order.map(company => {
    return {
        sideLabel: i18n.list[company].sideLabel,
        header: i18n.list[company].header,
        headerMobile: i18n.list[company].headerMobile,
        person: {
            quotation: i18n.list[company].quotation,
            quotationMobile: i18n.list[company].quotationMobile,
            name: i18n.list[company].name,
            position: i18n.list[company].position,
            img: specificsMap[company].img
        },
        icon1: {
            icon: company === 'perekrestok' ? Eye : Heart,
            increase: i18n.list[company].results[0].figure,
            text: i18n.list[company].results[0].desc
        },
        icon2: {
            icon: company === 'perekrestok' ? People : Arrow,
            increase: i18n.list[company].results[1].figure,
            text: i18n.list[company].results[1].desc
        },
        bgColor: specificsMap[company].bg,
        bgColorDark: specificsMap[company].contrastBg
    };
});

export type Story = (typeof stories)[number];

export default function SuccessStories() {
    const isMobileOrTablet = useIsMobileOrTablet();

    return (
        <div className={b()}>
            <h2 className={b('title')}>{i18n.title}</h2>
            {isMobileOrTablet ? (
                <DraggableSwiper pagination slidesPerView={1.08}>
                    {stories.map(story => (
                        <div
                            className={b('mobile-card-wrapper')}
                            style={{
                                background: story.bgColor
                            }}
                            key={story.bgColor}
                        >
                            <Slide
                                item={story}
                                header={story.headerMobile}
                                quotation={story.person.quotationMobile}
                            />
                        </div>
                    ))}
                </DraggableSwiper>
            ) : (
                <div className={b('accordion-wrapper')}>
                    <HorizontalAccordion list={stories} />
                </div>
            )}
        </div>
    );
}

function HorizontalAccordion({list}: {list: Story[]}) {
    const [activeIdx, setActiveIdx] = useState(0);

    const getWidth = () => `calc(95% - ${list.length * sideLabelWidth}px)`;

    return (
        <ul className={b('accordion')}>
            {list.map((item, idx) => (
                <li
                    className={b('accordion-item', {isActive: activeIdx === idx})}
                    style={{
                        background: item.bgColor,
                        transform: getTransition(idx, activeIdx, list.length)
                    }}
                    key={item.bgColor}
                >
                    <div className={b('accordion-slide')}>
                        <div
                            className={b('accordion-header-wrapper')}
                            onClick={() => setActiveIdx(idx)}
                        >
                            <div className={b('accordion-header')}>
                                {item.sideLabel}
                                <img
                                    src={Plus}
                                    alt='plus sign'
                                    className={b('accordion-plus-sign')}
                                />
                            </div>
                        </div>
                        <div className={b('accordion-body')} style={{width: getWidth()}}>
                            <Slide
                                item={item}
                                header={item.header}
                                quotation={item.person.quotation}
                            />
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );
}

function getTransition(idx: number, activeIndex: number, length: number) {
    if (idx === 0) return;

    if (idx === activeIndex) {
        return `translateX(calc( ${idx * sideLabelWidth}px))`;
    }

    if (idx > activeIndex) {
        return `translateX(calc(100% - ${(length - idx) * sideLabelWidth}px))`;
    }

    if (idx < activeIndex) {
        return `translateX(calc( ${idx * sideLabelWidth}px))`;
    }

    return 'none';
}
