import React, {useRef, useState} from 'react';
import b_ from 'b_';
import useWindowScroll from 'react-use/lib/useWindowScroll';
import useClickAway from 'react-use/lib/useClickAway';

import I18N from 'assets/i18n/root';

import {useIsMobile} from 'client/common/hooks/device';

import './form-widget.css';

const b = b_.with('form-widget');

const i18n = I18N.widget;

const FormWidget = () => {
    const iconRef = useRef(null);
    const mobileBtnRef = useRef(null);
    const isMobile = useIsMobile();
    const {y: scrollHeight} = useWindowScroll();
    const [showWideBtn, setShowWideBtn] = useState(false);

    useClickAway(mobileBtnRef, () => {
        if (showWideBtn) {
            setShowWideBtn(false);
        }
    });

    return (
        <div className={b('', {shown: isMobile && scrollHeight >= 150})}>
            {showWideBtn ? (
                <a
                    ref={mobileBtnRef}
                    onClick={() => {
                        setShowWideBtn(false);
                    }}
                    className={b('button-mobile', {
                        active: showWideBtn
                    })}
                >
                    <span className={b('button-text-mobile')}>{i18n.buttonText}</span>
                </a>
            ) : (
                <a
                    className={b('button')}
                    ref={iconRef}
                    href={showWideBtn ? '#' : '#feedback'}
                    onClick={isMobile ? () => setShowWideBtn(true) : () => {}}
                >
                    <div className={b('button-wrapper')}>
                        <span className={b('button-text')}>{i18n.buttonText}</span>
                    </div>
                </a>
            )}
        </div>
    );
};

export default FormWidget;
