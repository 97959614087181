import {Branding} from './revision';

export enum SkinType {
    Basic = 'BASIC',
    Custom = 'CUSTOM'
}

export enum SkinVersionState {
    Active = 'ACTIVE',
    Draft = 'DRAFT',
    Obsolete = 'OBSOLETE'
}

export enum SkinStatus {
    Active = 'ACTIVE',
    Draft = 'DRAFT',
    Obsolete = 'OBSOLETE'
}

export type SkinConfig = Omit<Branding, 'on' | 'skin'>;

export interface Skin {
    id: number;
    skvId: number;
    label: string;
    base_skin_id?: number;
    description?: string;
    login: string;
    skvState: SkinVersionState;
    status: SkinStatus;
    config: SkinConfig;
    active_version?: Pick<Skin, 'base_skin_id' | 'config'>
    has_changes: boolean;
    created_at: string;
    updated_at: string;
}
