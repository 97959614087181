import React, {useRef} from 'react';
import b_ from 'b_';
import {SwiperClass} from 'swiper/react';
import useWindowSize from 'react-use/lib/useWindowSize';

import I18N from 'assets/i18n/root';

import {useIsMobileOrTablet} from 'client/common/hooks/device';

import Avito from './avito.png';
import Faberlick from './faberlick.png';
import Fsk from './fsk.png';
import Hoff from './hoff.png';
import Renesans from './renesans.png';
import SladkiyMir from './sladkiy.png';
import Ugoria from './ugoria.png';
import Vtb from './vtb.png';

import DraggableSwiper from '../swiper/swiper';
import {TryBtn} from '../buttons';
import ArrowsPanel from '../arrows-panel';

import './reviews.css';

const i18n = I18N.reviews;
const b = b_.with('reviews');

interface ProfileProps {
    photo: string;
    name: string;
    role: string;
    company: string;
}

export const Profile = ({photo, name, role, company}: ProfileProps) => {
    return (
        <div className={b('profile')}>
            <div className={b('profile-photo')}>
                <img src={photo} alt='' />
            </div>
            <div>
                <div className={b('profile-name')}>{name}</div>
                <div className={b('profile-text')}>
                    {role}, <cite className={b('profile-company')}>{company}</cite>
                </div>
            </div>
        </div>
    );
};

const PHOTO_MAP = {
    faberlick: Faberlick,
    hoff: Hoff,
    vtb: Vtb,
    avito: Avito,
    fsk: Fsk,
    ugoria: Ugoria,
    renins: Renesans,
    sladkiyMir: SladkiyMir
};

type CompaniesReview = keyof typeof PHOTO_MAP;

function getSlidesPerView(width: number) {
    if (width > 1250) {
        return 2.5;
    }

    if (width > 960) {
        return 1.5;
    }

    if (width > 640) {
        return 2.1;
    }

    return 1.08;
}

const Reviews = () => {
    const {width} = useWindowSize();
    const isMobile = useIsMobileOrTablet();
    const controllerRef = useRef<SwiperClass | undefined>(undefined);

    return (
        <div className={b()}>
            <div className={b('info')}>
                <div>
                    <h3 className={b('title')}>
                        {i18n.titleNew} <span className={b('title-part')}>{i18n.titleNew2}</span>
                    </h3>
                    <div className={b('subtitle')}>{i18n.titleNew3}</div>
                </div>
                {!isMobile && (
                    <div className={b('info-right')}>
                        <ArrowsPanel
                            onLeftArrowClick={() => controllerRef.current?.slidePrev()}
                            onRightArrowClick={() => controllerRef.current?.slideNext()}
                        />
                    </div>
                )}
            </div>
            <div className={b('cards-list-wrapper')}>
                <DraggableSwiper
                    slidesPerView={getSlidesPerView(width)}
                    pagination={isMobile}
                    controller={controllerRef}
                >
                    {i18n.list.order
                        .split(',')
                        .map((name: string) => name.trim())
                        .map((companyName: CompaniesReview) => {
                            const review = i18n.list[companyName];

                            return (
                                <li className={b('card')} key={companyName}>
                                    <figure className={b('card-figure')}>
                                        <blockquote className={b('card-text')}>
                                            {review.text}
                                        </blockquote>
                                        <figcaption className={b('card-profile')}>
                                            <Profile
                                                photo={PHOTO_MAP[companyName]}
                                                name={review.name}
                                                role={review.role}
                                                company={review.companyName}
                                            />
                                        </figcaption>
                                    </figure>
                                </li>
                            );
                        })}
                </DraggableSwiper>
            </div>
            {isMobile && (
                <div className={b('try-button')}>
                    <TryBtn width='max' />
                </div>
            )}
        </div>
    );
};

export default Reviews;
