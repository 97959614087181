import React, {ReactNode} from 'react';
import b_ from 'b_';

import {openLink} from 'client/common/utils/window';

import './base-button.css';

const b = b_.with('landing-button');

export interface BaseButtonProps {
    children: ReactNode;
    size?: 'm' | 'l';
    url?: string;
    onClick?: () => void;
    width?: 'max' | 'auto';
}

export const BaseButton = ({
    onClick = () => {},
    children,
    size = 'l',
    url = '',
    width = 'auto'
}: BaseButtonProps) => {
    const handleClick = () => {
        openLink(url);
        onClick?.();
    };

    return (
        <a className={b({size, width})} href={url} onClick={handleClick}>
            {children}
        </a>
    );
};
