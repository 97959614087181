import React, {useState, useMemo, useEffect} from 'react';
import b_ from 'b_';

import {Button, Select, Spin} from 'client/common/components/lego';
import {
    useSetUserCurrency,
    useUserAvailableCountries,
    useUserCountryId
} from 'client/common/hooks/user';

import {getGeoIdFromDomain} from 'client/common/utils/tld';
import I18N from 'assets/i18n/brandlift-project';
import i18nGeo from 'assets/i18n/geo';
import {getSortedTranslations} from 'assets/i18n';

import {Currency} from 'common/currency';
import {Domain} from 'common/domain';
import {GeoCountry} from 'client/common/types';

import './country-modal.css';

const i18n = I18N.common.countryModal;
const b = b_.with('country-modal');

export interface Props {
    className?: string;
    onDone: () => void;
    tld: Domain;
}

const CURRENCY_PRIORITY_ORDER: Currency[] = ['RUB', 'USD', 'EUR', 'UZS', 'CHF', 'KZT', 'BYN'];

function compareCurrency(currencyA: Currency, currencyB: Currency) {
    const first = CURRENCY_PRIORITY_ORDER.indexOf(currencyA) || -1;
    const second = CURRENCY_PRIORITY_ORDER.indexOf(currencyB) || -1;

    if (first < second) {
        return -1;
    }

    if (first > second) {
        return 1;
    }

    return 0;
}

// eslint-disable-next-line max-statements
export default function CountryModal(props: Props) {
    const {className = '', onDone, tld} = props;

    const userCountryId = useUserCountryId();
    const userCountry = userCountryId ? String(userCountryId) : undefined;
    const userAvailableCountries = useUserAvailableCountries();

    const availableCountryIds = useMemo(
        () => Object.keys(userAvailableCountries.data || {}) as GeoCountry[],
        [userAvailableCountries.data]
    );

    const isInfoLoaded = userAvailableCountries.status === 'success';

    const [country, setCountry] = useState<GeoCountry>();
    const [currency, setCurrency] = useState<Currency>();

    const handleChangeCountry = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCountry(e.target.value as GeoCountry);
        setCurrency(undefined);
    };

    const handleChangeCurrency = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCurrency(e.target.value as Currency);
    };

    const countryOptions = useMemo(() => {
        const options =
            availableCountryIds.map(countryId => ({
                value: countryId,
                content: i18nGeo[countryId]
            })) ?? [];

        if (userCountry && !availableCountryIds.includes(userCountry as GeoCountry)) {
            options.push({
                value: userCountry as GeoCountry,
                content: i18nGeo[userCountry as GeoCountry]
            });
        }

        return options.sort(({content: first}, {content: second}) => first.localeCompare(second));
    }, [availableCountryIds, userCountry]);

    const currencyOptions = useMemo(() => {
        if (!country || !userAvailableCountries.data?.[country]) {
            return [];
        }

        return userAvailableCountries.data[country]
            .map(value => ({
                value,
                content: i18n.currency[value]
            }))
            .sort(({value: first}, {value: second}) => compareCurrency(first, second));
    }, [country, userAvailableCountries.data]);

    const [isSaving, setIsSaving] = useState(false);

    const setUserCurrency = useSetUserCurrency();

    const onSave = async () => {
        if (!currency) {
            return;
        }

        setIsSaving(true);

        const {status} = await setUserCurrency({currency, countryId: country as GeoCountry});

        setIsSaving(false);

        if (status < 300) {
            onDone();
        }
    };

    const countryFromTld = getGeoIdFromDomain(tld) || '225';

    // Выставляем дефолтную страну автоматически
    useEffect(() => {
        if (country || !countryOptions.length) {
            return;
        }

        if (userCountry) {
            return setCountry(userCountry as GeoCountry);
        }

        setCountry(
            countryOptions.map(({value}) => value).includes(countryFromTld)
                ? countryFromTld
                : countryOptions[0].value
        );
    }, [country, setCountry, userCountry, countryOptions, countryFromTld]);

    // Выставляем дефолтную валюту автоматически
    useEffect(() => {
        if (currency || !currencyOptions.length) {
            return;
        }

        setCurrency(currencyOptions[0].value);
    }, [currency, setCurrency, currencyOptions]);

    return (
        <div className={`${b()} ${className}`}>
            <h3 className={b('title')}>{i18n.title}</h3>
            <div className={b('content')}>
                {getSortedTranslations(i18n.description).map((text, key) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <p key={key} className={b('description')}>
                        {text}
                    </p>
                ))}
            </div>
            <div className={b('select-wrapper')}>
                <span className={b('select-label')}>{i18n.label.country}</span>
                {isInfoLoaded ? (
                    <Select
                        placeholder={i18n.placeholder.selectCountry}
                        onChange={handleChangeCountry}
                        options={countryOptions}
                        value={country}
                        view='default'
                        size='m'
                        width='max'
                        disabled={Boolean(userCountry)}
                    />
                ) : (
                    <Spin view='default' size='xs' progress />
                )}
            </div>
            <div className={b('select-wrapper')}>
                <span className={b('select-label')}>{i18n.label.currency}</span>
                {isInfoLoaded ? (
                    <Select
                        placeholder={i18n.placeholder.selectCurrency}
                        onChange={handleChangeCurrency}
                        options={currencyOptions}
                        value={currency}
                        disabled={!country}
                        view='default'
                        size='m'
                        width='max'
                    />
                ) : (
                    <Spin view='default' size='xs' progress />
                )}
            </div>
            <div className={b('buttons')}>
                <Button
                    view='action'
                    size='m'
                    disabled={!currency || !country || isSaving}
                    onClick={onSave}
                    progress={isSaving}
                >
                    {i18n.button.save}
                </Button>
            </div>
        </div>
    );
}
