import React, {Fragment, useState} from 'react';
import b_ from 'b_';
import {Dropdown} from '@yandex-lego/components/Dropdown/desktop';
import I18N from 'assets/i18n/brandlift-project';

import {SurveysGoal} from 'client/common/types';
import {Icon, Menu} from 'client/common/components/lego';
import HelpCreate from 'client/common/components/help-create';
import LangSwitcher from 'client/common/components/lang-switcher/lang-switcher';

import {useModal} from 'client/common/hooks/modal';
import {useDevice} from 'client/common/hooks/device';
import {sendGoal} from 'client/common/utils/metrika';
import {openLink} from 'client/common/utils/window';
import {Device} from 'client/common/utils/device-width';
import {tld} from 'client/common/utils/tld';
import {createUrl} from 'client/common/utils/history';
import {LinkPage} from 'client/common/utils/header-link';
import {useUser} from 'client/common/contexts/user';
import {useCurrentOrg} from 'client/common/hooks/orgs';
import {getCurrentOrg} from 'client/common/hooks/orgs/utils';

import {Domain} from 'common/domain';

import {HeaderButton, HeaderProps} from './types';
import {CreativesHelpUrls} from './consts';
import {testIds} from './test-ids';

import './header2.css';

const i18n = I18N.common.header2;
const b = b_.with('header2');
const meaningfulElemName = 'menu-link';

function CreativesDropdown() {
    const withCreatives = tld === Domain.Ru;

    const [isOpen, setIsOpen] = useState(false);

    return withCreatives ? (
        <span className={b(meaningfulElemName, {type: 'creatives-menu'})}>
            <Dropdown
                onVisibleChange={visible => {
                    setIsOpen(visible);
                }}
                view='default'
                trigger='click'
                content={<CreativesMenu />}
            >
                <div>
                    <span>{i18n.creatives}</span>
                    <Icon
                        type='arrow'
                        className={b('arrow', {type: isOpen ? 'open' : ''})}
                        size='m'
                    />
                </div>
            </Dropdown>
        </span>
    ) : null;
}

function CreativesMenu() {
    const org = getCurrentOrg();

    const CREATIVE_LINKS = [
        {
            value: 'creatives',
            content: (
                <a
                    className={b(meaningfulElemName, {type: 'creatives'})}
                    href={createUrl({
                        link: i18n.links.creatives,
                        extraSearchParams: {org}
                    })}
                >
                    {i18n.list.creatives}
                </a>
            )
        },
        {
            value: 'bordomatiks',
            content: (
                <a
                    className={b(meaningfulElemName, {type: 'bordomatiks'})}
                    href={createUrl({
                        link: i18n.links.bordomatiks,
                        extraSearchParams: {org}
                    })}
                >
                    {i18n.list.bordomatiks}
                </a>
            )
        }
    ];

    return (
        <Menu
            size='m'
            view='default'
            items={CREATIVE_LINKS}
            onChange={e => openLink(i18n.links[e.target.value as 'creatives' | 'bordomatiks'])}
        />
    );
}

function NewSurveyButton() {
    const org = useCurrentOrg();

    return (
        <a
            className={b(meaningfulElemName, {type: 'surveys'})}
            href={createUrl({link: LinkPage.Quicksurvey, extraSearchParams: {org}})}
            data-testid={testIds.newSurvey}
        >
            {i18n.newSurvey}
        </a>
    );
}

function SurveysListLink() {
    const location = window.location.href;
    const linkIdentifier = 'quicksurvey/list';
    const isActive = location.substring(location.length - linkIdentifier.length) === linkIdentifier;

    const org = useCurrentOrg();

    return (
        <a
            className={b(meaningfulElemName, {
                type: 'surveys',
                background: isActive ? 'active' : ''
            })}
            href={createUrl({link: i18n.links.surveys, extraSearchParams: {org}})}
            data-testid={testIds.listSurvey}
        >
            {i18n.surveys}
        </a>
    );
}

type MenuLinksProps = Pick<HeaderProps, 'boxType'> & {
    buttons: HeaderButton[];
    isLangSwitchable: boolean;
};

export function MenuLinksDevice(props: MenuLinksProps) {
    const {buttons} = props;

    const device = useDevice();

    return (
        <Fragment>
            {device === Device.Tablet && <SurveysListLink />}
            {device === Device.Tablet && <CreativesDropdown />}
            {device === Device.Mobile && buttons.includes('create') && <NewSurveyButton />}
        </Fragment>
    );
}

export function MenuLinksDesktop(props: {buttons: string[]}) {
    return (
        <Fragment>
            <SurveysListLink />
            <CreativesDropdown />
            {props.buttons.includes('create') && <NewSurveyButton />}
        </Fragment>
    );
}

export function MenuLinksAsPopup(props: MenuLinksProps) {
    const {boxType, buttons, isLangSwitchable} = props;
    const user = useUser();

    const [showModal] = useModal();

    const handleShowHelpModal = () => {
        sendGoal(SurveysGoal.ClickHelpHeaderButton);

        showModal(<HelpCreate boxType={boxType} />, {withCross: true});
    };

    return (
        <Fragment>
            <SurveysListLink />
            <NewSurveyButton />
            <CreativesDropdown />
            <a
                className={b(meaningfulElemName, {type: 'feedback'})}
                href={createUrl({link: i18n.links.feedback.replace('%s', user?.info?.login ?? '')})}
                target='_blank'
                rel='noopener noreferrer'
            >
                {i18n.feedback}
            </a>
            {buttons.includes('help') && (
                <span
                    className={b(meaningfulElemName, {type: 'help'})}
                    onClick={handleShowHelpModal}
                >
                    {i18n.helpWithSurvey}
                </span>
            )}
            {buttons.includes('help-link') &&
                (boxType === 'creative' || boxType === 'bordomatik') && (
                    <a
                        className={b(meaningfulElemName, {type: 'help'})}
                        target='_blank'
                        href={CreativesHelpUrls[boxType]}
                        rel='noopener noreferrer'
                    >
                        {i18n.helpWithSurvey}
                    </a>
                )}
            {isLangSwitchable && (
                <LangSwitcher
                    className={b(meaningfulElemName, {type: 'lang'})}
                    withLabel
                    lang={user?.lang ?? 'ru'}
                />
            )}
        </Fragment>
    );
}
