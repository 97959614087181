import {GetSurveyProgressStatResponse, SurveySlug} from 'client/common/types';

import {_doRequest} from '../base';
import {
    GetSurveyStatParams,
    GetSurveyStatResponse,
    GetUnlockedSurveysCountParams,
    GetUnlockedSurveysCountResponse,
    UploadTagsResponse
} from '../types';

export const getUnlockedSurveysStatistics = async ({
    slug
}: GetUnlockedSurveysCountParams): Promise<GetUnlockedSurveysCountResponse> => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `report/survey/${slug}/unlocked`
    };

    return await _doRequest(requestParams);
};

// eslint-disable-next-line max-statements
export const getStat = async (statParams: GetSurveyStatParams): Promise<GetSurveyStatResponse> => {
    const {slug, type, params = {}} = statParams;

    const chartTypes = ['events', 'progress', 'answers', 'clicks'];
    let url = `report/survey/${slug}`;

    if (chartTypes.includes(type)) {
        url += `/charts/${type}`;
    } else if (type === 'stat') {
        url += '/stats';
    } else if (type === 'creative') {
        url += '/creative_stats';
    } else {
        throw new Error('Unknown type of stat method');
    }

    // Questions: [1,2,3] => ?question=1&question=2&question=3
    const urlParams = Object.keys(params)
        .filter(key => params[key] !== undefined)
        .reduce((str, key) => {
            const extra = params[key].reduce((extraStr: string, value: string) => {
                extraStr += `${key}=${value}&`;

                return extraStr;
            }, '');

            str += extra;

            return str;
        }, '?');

    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: (url += urlParams)
    };

    return _doRequest(requestParams);
};

export const getQuestionTextAnswers = async ({slug, question_id, offset}: any) => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `report/survey/${slug}/charts/answers/${question_id}/${offset}`
    };

    return _doRequest(requestParams);
};

export const getQuestionChartAnswers = async ({slug, question_id, groupBy}: any) => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `report/survey/${slug}/charts/answers?question=${question_id}&date-group=${groupBy}`
    };

    return _doRequest(requestParams);
};

export const getSurveyDataProgress = async ({
    slug,
    groupBy
}: {
    slug: string;
    groupBy: string;
}): Promise<GetSurveyProgressStatResponse> => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `report/survey/${slug}/charts/interviews?date-group=${groupBy}`
    };

    return _doRequest(requestParams);
};

export const getQuestionTextAnswersCount = async ({slug, question_id}: any) => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `report/survey/${slug}/charts/answers/${question_id}/count`
    };

    return _doRequest(requestParams);
};

export const generateExcel = async ({
    slug,
    lang = 'ru'
}: {
    slug: SurveySlug;
    lang?: 'ru' | 'en';
}) => {
    const requestParams = {
        method: 'POST',
        retries: [3000],
        url: `report/survey/${slug}/excel?lang=${lang}`
    };

    return _doRequest(requestParams);
};

export const downloadReportFile = async ({
    slug,
    lang = 'ru',
    type
}: {
    slug: SurveySlug;
    lang?: 'ru' | 'en';
    type?: string;
}) => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        blob: true,
        url: `report/survey/${slug}/cached_export?lang=${lang}`
    };

    if (type) {
        requestParams.url += `&report_type=${type}`;
    }

    return _doRequest(requestParams);
};

export const generateSpss = async ({slug, lang}: {slug: SurveySlug; lang: 'ru' | 'en'}) => {
    const requestParams = {
        method: 'POST',
        retries: [3000],
        url: `report/survey/${slug}/spss?lang=${lang}`
    };

    return _doRequest(requestParams);
};

export const uploadTagsFile = async ({
    slug,
    file
}: {
    slug: SurveySlug;
    file: FormData;
}): Promise<UploadTagsResponse> => {
    const requestParams = {
        method: 'POST',
        retries: [3000],
        url: `report/survey/${slug}/excel/upload-tags`,
        file
    };

    return _doRequest(requestParams);
};

export const downloadQuestionnaire = async (slug: SurveySlug) => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `report/export/${slug}/docx`,
        blob: true
    };

    return _doRequest(requestParams);
};

export const getTaskStatus = async ({taskId}: {taskId: string}) => {
    const requestParams = {
        method: 'GET',
        retries: [3000],

        url: `report/task/${taskId}`
    };

    return _doRequest(requestParams);
};

export const getTagsList = async (slug: SurveySlug) => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `report/survey/${slug}/tags`
    };

    return _doRequest(requestParams);
};
