/* Скопипастили из ts-extras из-за проблем с поддержкой ES6-модулей из тестов jest */
export function arrayIncludes<Type extends SuperType, SuperType = unknown>(
    array: Type[] | readonly Type[],
    item: SuperType,
    fromIndex?: number
): item is Type {
    return array.includes(item as Type, fromIndex);
}

export type ObjectKeys<T extends object> = `${Exclude<keyof T, symbol>}`;
export const objectKeys = Object.keys as <Type extends object>(
    value: Type
) => Array<ObjectKeys<Type>>;
/** переводит в строчный тип: `1 | 2` => `'1' | '2'` */
export type ToString<S> = S extends infer N extends number ? `${N}` : never;

export type ValueOf<T> = T[keyof T];
export type KeysOfUnion<T> = T extends T ? keyof T : never;

export type DeepRequired<T> = {
    [P in keyof T]-?: T[P] extends object ? DeepRequired<T[P]> : T[P];
};
