import {sendGoalToMetrika} from '@yandex-int/pythia-libs/lib/metrika-postman';

import I18N from 'assets/i18n/brandlift-project';
import NotificationManager from 'client/common/components/notifications/manager';
import cfg from 'client/common/utils/client-config';

interface MetrikaErrorGoal {
    [key: string]: string;
}

const METRIKA_ERROR_GOAL: MetrikaErrorGoal = {
    survey: 'open_form__fail',
    statistics: 'open_statistics__fail'
};

export function handleErrorStatus(status: number): void {
    if (status === 403 && !location.pathname.includes('/statistics/')) {
        NotificationManager.add({
            type: 'error',
            timeOut: 5000,
            message: I18N.common.error.notEnoughRights,
            x: 'center',
            y: 'bottom'
        });
    }

    if (status >= 400) {
        const [, box, page] = window.location.pathname.split('/');

        if (
            box === 'brandlift' &&
            Object.keys(METRIKA_ERROR_GOAL).includes(page) &&
            cfg.metrika.counters &&
            cfg.metrika.counters.brandlift
        ) {
            void sendGoalToMetrika(cfg.metrika.counters.brandlift, METRIKA_ERROR_GOAL[page]);
        }
    }
}
