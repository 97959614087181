import {OrgRolePermission} from 'client/common/types';
import {_doRequest} from 'client/common/utils/api-client/base';
import {ErrorResponse, SuccessfulResponse} from 'client/common/utils/api-client/types';

export interface OrgRole {
    id: number;
    name: string;
    permissions: OrgRolePermission[];
    count: number;
}

export interface OrgRolesResponse {
    roles: OrgRole[];
}

export const getOrgRoles = async ({
    orgId
}: {
    orgId: number;
}): Promise<SuccessfulResponse<OrgRolesResponse> | ErrorResponse> => {
    const requestParams = {
        method: 'GET',
        prefix: '/api/v1',
        url: `/orgs/${orgId}/roles`,
        passQuery: false
    } as const;

    return _doRequest(requestParams);
};

export interface OrgRoleResponse {
    role: OrgRole;
    users: {
        userid: number;
        lang: string;
        email: string;
        login: string;
        displayName: string;
        avatar: {default: string};
    }[];
}

export const getOrgRole = async ({
    orgId,
    roleId
}: {
    orgId: number;
    roleId: number;
}): Promise<SuccessfulResponse<OrgRoleResponse>> => {
    const requestParams = {
        method: 'GET',
        prefix: '/api/v1',
        url: `/orgs/${orgId}/roles/${roleId}`,
        passQuery: false
    } as const;

    return _doRequest(requestParams);
};

export const addOrgRole = async ({
    orgId,
    name,
    permissions
}: {
    orgId: number;
    name: string;
    permissions?: string[];
}): Promise<SuccessfulResponse<OrgRole>> => {
    const requestParams = {
        method: 'POST',
        prefix: '/api/v1',
        url: `/orgs/${orgId}/roles`,
        passQuery: false,
        data: {
            name,
            ...(permissions && {permissions})
        }
    } as const;

    return _doRequest(requestParams);
};

export const updateOrgRole = async ({
    orgId,
    roleId,
    name,
    permissions
}: {
    orgId: number;
    roleId: number;
    name?: string;
    permissions?: string[];
}): Promise<SuccessfulResponse<OrgRole>> => {
    const requestParams = {
        method: 'PATCH',
        prefix: '/api/v1',
        url: `/orgs/${orgId}/roles/${roleId}`,
        passQuery: false,
        data: {
            ...(name && {name}),
            ...(permissions && {permissions})
        }
    } as const;

    return _doRequest(requestParams);
};

export const deleteOrgRole = async ({
    orgId,
    roleId
}: {
    orgId: number;
    roleId: number;
}): Promise<SuccessfulResponse<OrgRole>> => {
    const requestParams = {
        method: 'DELETE',
        prefix: '/api/v1',
        url: `/orgs/${orgId}/roles/${roleId}`,
        passQuery: false
    } as const;

    return _doRequest(requestParams);
};

export const addOrgRoleForUser = async ({
    orgId,
    roleId,
    userid
}: {
    orgId: number;
    roleId: number;
    userid: number;
}): Promise<SuccessfulResponse<OrgRole>> => {
    const requestParams = {
        method: 'POST',
        prefix: '/api/v1',
        url: `/orgs/${orgId}/users/${userid}/roles/${roleId}/`,
        passQuery: false
    } as const;

    return _doRequest(requestParams);
};

export const removeOrgRoleForUser = async ({
    orgId,
    roleId,
    userid
}: {
    orgId: number;
    roleId: number;
    userid: number;
}): Promise<SuccessfulResponse<OrgRole>> => {
    const requestParams = {
        method: 'DELETE',
        prefix: '/api/v1',
        url: `/orgs/${orgId}/users/${userid}/roles/${roleId}/`,
        passQuery: false
    } as const;

    return _doRequest(requestParams);
};
