import {_doRequest} from '../../base';
import {ToggleScreeningParams} from '../../types';

export const toggleScreening = async ({slug, revisionId}: ToggleScreeningParams) => {
    const requestParams = {
        method: 'POST',
        url: `survey/${slug}/revision/${revisionId}/group/toggleScreening`
    };

    return _doRequest(requestParams);
};
