import {_doRequest} from '../../base';
import {
    CopyPageParams,
    RemoveItemParams,
    UpdatePageParams,
    CreateGroupParams,
    CreateGroupResponse
} from '../../types';

export const removePage = async ({slug, revisionId, id, force}: RemoveItemParams) => {
    const requestParams = {
        method: 'DELETE',
        url: `survey/${slug}/revision/${revisionId}/group/${id}?force=${force}`
    };

    return _doRequest(requestParams);
};
export const updatePage = async ({slug, revisionId, id, data, force}: UpdatePageParams) => {
    const requestParams = {
        method: 'PATCH',
        url: `survey/${slug}/revision/${revisionId}/group/${id}?force=${force}`,
        data
    };

    return _doRequest(requestParams);
};
export const copyPage = async ({slug, revisionId, pageId}: CopyPageParams) => {
    const requestParams = {
        method: 'POST',
        url: `survey/${slug}/revision/${revisionId}/group/${pageId}/copy`
    };

    return _doRequest(requestParams);
};
export const createPage = async ({
    slug,
    revisionId,
    groupId,
    isScreening = false
}: CreateGroupParams): Promise<CreateGroupResponse> => {
    const requestParams = {
        method: 'POST',
        url: `survey/${slug}/revision/${revisionId}/group${
            groupId === undefined ? '' : `?groupId=${groupId}`
        }`,
        data: {type: 'page', isScreening}
    };

    return _doRequest(requestParams);
};
