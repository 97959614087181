import {UseQueryResult, useQuery} from 'react-query';

import {Currency} from 'common/currency';
import {Experiments, GeoCountry} from 'client/common/types';
import {isYandexTeamDomain} from 'client/common/utils/yandex-team';

import {getExperiments, getUserAvailableCountries} from 'client/common/utils/api-client';
import {useUser} from 'client/common/contexts/user';
import {usePagesContext} from 'client/common/contexts/pages';

import {useIsSuperuser} from './roles';

const USER_EXPERIMENTS_KEY = 'user-experiments';
const USER_AVAILABLE_COUNTRIES = 'user-available-countries';

export function useUserEmail(): string | undefined {
    const user = useUser();

    return user?.info?.email;
}

export function useUserLogin(): string | undefined {
    const user = useUser();

    return user?.info?.login;
}

export function useUserSelectedOrgId(): number | undefined {
    const user = useUser();

    return isYandexTeamDomain ? user?.info?.settings.selected_org_id : undefined;
}

export function useExperiments(): UseQueryResult<Experiments> {
    return useQuery(USER_EXPERIMENTS_KEY, async () => {
        const {data} = await getExperiments();

        return data;
    });
}

export function useUserCountryId(): number | undefined {
    const user = useUser();

    return user?.info?.settings.country_id ?? undefined;
}

export function useHaveSurveys(): boolean | undefined {
    const user = useUser();

    return user?.info?.hasSurveys;
}

export function useUserAvailableCountries(): UseQueryResult<Record<GeoCountry, Currency[]>> {
    return useQuery(USER_AVAILABLE_COUNTRIES, async () => {
        const {data} = await getUserAvailableCountries();

        return data?.countries;
    });
}

export function useIsNonSuperInScreenouts() {
    const isSuperuser = useIsSuperuser();
    const {isScreening} = usePagesContext();

    return isScreening && !isSuperuser;
}

export function useOwnerId() {
    const user = useUser();
    const ownerId = user?.info?.authInfo.owner_uid as string;

    return ownerId;
}

export * from './currency';
export * from './features';
export * from './profile';
export * from './roles';
