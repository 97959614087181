import React, {useState} from 'react';
import b_ from 'b_';

import I18N from 'assets/i18n/brandlift-project';
import I18N_Root from 'assets/i18n/root';

import {LinkPage} from 'client/common/utils/header-link';
import {createUrl} from 'client/common/utils/history';
import {Button, Icon} from 'client/common/components/lego';
import LangSwitcher from 'client/common/components/lang-switcher/lang-switcher';
import {Lang} from 'client/common/types';
import {useIsAuthorized} from 'client/common/hooks/user';
import {genLoginLink} from 'client/common/utils/user-link';

import {UserPic} from 'client/common/components/header2/userpic';

import {BaseButton} from '../buttons';

import LogoIcon from './logo.svg';

import './header.css';

const b = b_.with('header');

const i18n = I18N.common.header2;

const meaningfulElemName = 'menu-link';
const meaningfulWrapperClassName = b('menu', {type: 'main'});

const header_i18n = I18N_Root.header;

export const headerMeaningfulSelector = `.${meaningfulWrapperClassName} .${b(meaningfulElemName)}`;

export function Header({lang}: {lang: Lang}) {
    const [opened, setOpened] = useState(false);

    const isUserLogedIn = useIsAuthorized();

    const handleOpenClick = () => {
        setOpened(isOpened => !isOpened);
    };

    return (
        <div className={b()}>
            <div className={meaningfulWrapperClassName}>
                <a className={b(meaningfulElemName)} href={createUrl({link: LinkPage.Landing})}>
                    <img
                        className={b('title-logo')}
                        alt={i18n.logo.yandex}
                        src={`https://yastatic.net/q/logoaas/v2/${i18n.logo.yandex}%20.svg?size=32&color=1B202B`}
                    />
                    <img className={b('logo')} src={LogoIcon} />
                    <img
                        className={b('service-logo')}
                        alt={i18n.logo.surveys}
                        src={`https://yastatic.net/q/logoaas/v2/%20${i18n.logo.surveys}.svg?size=32&color=1B202B`}
                    />
                </a>
            </div>
            <div className={b('menu', {type: 'links', opened: opened ? 'yes' : 'no'})}>
                <Button view='clear' type='link' url='#features'>
                    {header_i18n.features}
                </Button>
                <Button view='clear' type='link' url='#reviews'>
                    {header_i18n.reviews}
                </Button>
                <Button view='clear' type='link' url='#faq'>
                    {header_i18n.faq}
                </Button>
                <LangSwitcher
                    className={b('lang-switcher', {type: 'mobile'})}
                    lang={lang}
                    withLabel
                    updated
                />
            </div>
            <div className={b('menu', {type: 'misc'})}>
                {isUserLogedIn ? (
                    <div className={b('user-pic')}>
                        <UserPic />
                    </div>
                ) : (
                    <BaseButton size='m' url={genLoginLink()}>
                        {header_i18n.loginBtnText}
                    </BaseButton>
                )}
                <Button
                    className={b('mobile-menu-btn')}
                    size='m'
                    view='clear'
                    icon={(iconClassName: string) => (
                        <Icon
                            className={iconClassName}
                            type={opened ? 'cross-bold' : 'menu-burger'}
                        />
                    )}
                    onClick={handleOpenClick}
                />
                <LangSwitcher
                    className={b('lang-switcher', {type: 'desktop'})}
                    lang={lang}
                    size='m'
                    updated
                    withLabel
                />
            </div>
        </div>
    );
}
