import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './eye-open.css';

export interface IconTypeEyeOpenProps {
    type?: 'eye-open';
}

export const withTypeEyeOpen = withBemMod<IconTypeEyeOpenProps>(cnIcon(), {
    type: 'eye-open'
});
