import {getIntParamFromSearch} from 'client/common/utils/history';
import {queryClient} from 'client/common/utils/react-query';

export const ORG_PARAM_NAME = 'org';
export const ORGS_KEY = 'ORGS';
export const ORGS_CURRENT_KEY = [ORGS_KEY, 'current'] as const;

export const getOrgKey = (orgId: number) => {
    return [ORGS_KEY, orgId] as const;
};

export const getOrgSurveysKey = (orgId: number) => {
    return [ORGS_KEY, orgId, 'surveys'] as const;
};

export const getOrgUsersKey = (orgId: number) => {
    return [ORGS_KEY, orgId, 'users'] as const;
};

export const getOrgRolesKey = (orgId: number) => {
    return [ORGS_KEY, orgId, 'roles'] as const;
};

export const getOrgRoleKey = (orgId: number, roleId: number) => {
    return [ORGS_KEY, orgId, 'roles', roleId] as const;
};

export function getCurrentOrg(): number | undefined {
    return queryClient.getQueryData(ORGS_CURRENT_KEY);
}

export function getOrgFromUrl() {
    return getIntParamFromSearch(ORG_PARAM_NAME);
}
