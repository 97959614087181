import merge from 'lodash/merge';

import {Config, PartialConfig} from '@yandex-int/yandex-cfg';

import {tld} from 'client/common/utils/tld';

const TESTINGS_REGEX = /^test([0-9]+).staging.pythia.*/;

declare const CONFIG: Config;

const DEFAULT_CLIENT_CONFIG: PartialConfig = {
    passport: {
        front: {host: CONFIG.passport.front.host.replace('$tld', tld)},
        api: {host: CONFIG.passport.api.host.replace('$tld', tld)}
    },
    api: {
        ...(CONFIG.api.host && {host: CONFIG.api.host.replace('$tld', tld)})
    },
    surveyEndpoint: CONFIG.surveyEndpoint.replace('$tld', tld),
    publicEndpoint: `${CONFIG.publicEndpoint.replace('$tld', tld)}${CONFIG.api.path}`
};

const YATEAM_PROD_CONFIG_OVERRIDE: PartialConfig = {
    adminEndpoint: 'https://admin.pythia.yandex-team.ru',
    publicEndpoint: `https://pythia.yandex-team.ru${CONFIG.api.path}`
};

const YATEAM_STAGING_CONFIG_OVERRIDE: PartialConfig = {
    adminEndpoint: 'https://admin-staging.pythia.yandex-team.ru',
    publicEndpoint: `https://staging.pythia.yandex-team.ru${CONFIG.api.path}`
};

const LOCAL_YATEAM_CONFIG_OVERRIDE: PartialConfig = {
    api: {
        host: 'staging.pythia.yandex-team.$tld'.replace('$tld', tld)
    }
};

const STAGING_CONFIG_OVERRIDE: PartialConfig = {
    metrika: {
        counters: {
            brandlift: 52953796,
            surveys: 46267350
        }
    },

    avatarsHost: 'https://avatars.mdst.yandex.net',
    preview: {
        hosts: {
            default: 'https://pythia-demo-test.common.yandex.ru'
        }
    },
    quicksurveyFeedbackPollLink: 'https://preview.surveys.yandex.ru/poll/9WPRDTo9kWneP9Zmw1GLcE',
    adminEndpoint: 'https://admin-staging.pythia.yandex-team.ru',
    publicDefaultUrl: 'https://pythia-staging.common.yandex.ru',
    teamEndpoint: 'https://staging.pythia.yandex-team.ru',
    surveyEndpoint: 'https://l7test.yandex.$tld/poll'.replace('$tld', tld),
    publicEndpoint: `${'https://pythia-staging.common.yandex.$tld'.replace('$tld', tld)}${
        CONFIG.api.path
    }`,

    demoSlugs: {
        creative: ['PQo4fFQHKiVR1yhyLGh8x1', 'HWWx4X1CLKc6smSujnKYKb'],
        bordomatik: ['8JjYZ6sVC7dGTfdwAfCPwU'],
        preview: {
            yandexActiveFeedback: 'NxDpU5nVqTgSHHGyaaGDVj',
            yandexPassiveFeedback: 'EgXrVjANmtAytTpaQKWemx'
        }
    },

    pollToast: {
        creatives: {
            questionId: 1,
            slug: 'HaMn1CbBtwvVgioWBJeqcW'
        }
    },

    resourceVideoOrigin: 'https://vh.test.yandex.ru',

    feedbackOwnerId: '3d948da5-7223-4957-ae93-56f4406630c0',
    feedbackEnvironment: 'development',
    minNoAnnoyValueMS: 10000,
    statisticsMinRespondentsCount: 0
};

const AUTO_STAGING_CONFIG_OVERRIDE: PartialConfig = {
    preview: {
        hosts: {
            default: `https://preview-${location.host}`,
            yandexNet: `https://preview-${location.host}`,
            yandexTeam: `https://preview-${location.host}`
        }
    },
    demoSlugs: {
        preview: {
            yandexActiveFeedback: 'NxDpU5nVqTgSHHGyaaGDVj',
            yandexPassiveFeedback: 'EgXrVjANmtAytTpaQKWemx'
        }
    }
};

function isStagingHost() {
    return (
        location.host.includes('.common.yandex.') ||
        location.host.includes('.staging.pythia.yandex.') ||
        location.host.includes('staging.pythia.yandex-team.')
    );
}

function isAutoStagingHost() {
    return /pr-([0-9])+\.staging\.pythia\.yandex(-team)?\.(.*)/.test(location.host);
}

function isLocalHost() {
    return (
        location.host.startsWith('localhost.msup.yandex.') ||
        location.host.startsWith('localhost.msup.yandex-team.')
    );
}

function isYaTeamHost() {
    return location.host.endsWith('.yandex-team.ru');
}

function getConfig(): Config {
    const config = merge({}, CONFIG, DEFAULT_CLIENT_CONFIG);

    if (isLocalHost() && isYaTeamHost()) {
        merge(config, LOCAL_YATEAM_CONFIG_OVERRIDE);
    } else if (isStagingHost()) {
        merge(config, STAGING_CONFIG_OVERRIDE);
        if (isYaTeamHost()) {
            merge(config, YATEAM_STAGING_CONFIG_OVERRIDE);
        }
        if (isAutoStagingHost()) {
            merge(config, AUTO_STAGING_CONFIG_OVERRIDE);
        }
    } else if (isYaTeamHost()) {
        merge(config, YATEAM_PROD_CONFIG_OVERRIDE);
    }

    if (TESTINGS_REGEX.test(location.host)) {
        config.preview.hosts.default = location.origin;
    }

    return config;
}

export {isStagingHost, isYaTeamHost};
export default getConfig();
